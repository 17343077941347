import fetch, { METHOD, TYPE, fileUploadS3 } from '../fetch'

export const list = async (token) => {
  const { status, estates } = await fetch(METHOD.GET, '/estate/list', { token })
  if (status && estates) {
    return estates
  }
  return false
}

export const get = async (uuid) => {
  const { status, estate } = await fetch(METHOD.GET, '/estate/get', { uuid })
  if (status && estate) {
    return estate
  }
  return false
}

export const upsert = async (token, estate) => {
  if (Array.isArray(estate.image) && estate.image[0]) {
    const image = estate.image[0]
    const imageKey = await fileUploadS3(token, image, TYPE.ESTATE)
    estate.imageKey = imageKey
  }
  const { status } = await fetch(METHOD.POST, '/estate/upsert', {
    token,
    estate,
  })
  return status
}

export const remove = async (token, uuid) => {
  const { status } = await fetch(METHOD.DELETE, '/estate/remove', {
    token,
    uuid,
  })
  return status
}

export const search = async (input) => {
  const { status, estates } = await fetch(METHOD.GET, '/estate/search', {
    input,
  })
  return status && Array.isArray(estates) ? estates : false
}
