import Layout from '../../UI/Layout/Layout'
import { StyledEstates } from './Estates.Styled'
import { list } from '../../../interface/estate'
import { useState } from 'react'
import { useEffect } from 'react'
import Headline from '../../UI/Headline/Headline'
import { useSelector } from 'react-redux'
import Container from '../../UI/Container/Container'
import Form from './Form/Form'
import Nav from '../Nav/Nav'
import List from './List/List'

const Estates = () => {
  const translation = useSelector((s) => s.translation)
  const token = useSelector((s) => s.userToken)
  const theme = useSelector((s) => s.theme)
  const [estates, setEstates] = useState(null)

  useEffect(() => {
    const fetch = async () => {
      const estates = await list(token)
      setEstates(estates)
    }

    if (!estates && token) {
      fetch()
    }
  }, [estates, token])

  return (
    <Layout authRequired={true}>
      <Nav />
      <StyledEstates>
        <Container>
          <Headline
            text={translation.admin.estates.create.headline}
            withSeparator={true}
            color={theme.color.white}
          />
          <Form />
          {estates && (
            <>
              <Headline
                text={translation.admin.estates.list.headline}
                withSeparator={true}
                color={theme.color.white}
                margin="75px 0 0 0"
              />
              <List estates={estates} />
            </>
          )}
        </Container>
      </StyledEstates>
    </Layout>
  )
}

export default Estates
