import styled from "styled-components";

export const StyledItem = styled.div`
  width: ${({ width }) => width || "100%"};
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    width: 100%;
  }
`;

export const Header = styled.div`
  background-color: ${({ background, theme }) =>
    background || theme.color.blue};
  color: ${({ color, theme }) => color || theme.color.white};
  padding: 10px 15px;
  position: relative;
  border-radius: ${({ theme, borderRadius }) =>
    borderRadius ? `${borderRadius}px` : theme.border.radius};
  cursor: pointer;
  .plus-icon {
    position: absolute;
    top: 10px;
    right: 15px;
  }
`;

export const Body = styled.div`
  padding: 30px 20px;
  background-color: ${({ background, theme }) =>
    background || theme.color.blue};
  color: ${({ color, theme }) => color || theme.color.black};
  border-radius: ${({ theme, borderRadius }) =>
    borderRadius ? `${borderRadius}px` : theme.border.radius};
`;
