import Layout from '../UI/Layout/Layout'
import { StyledImprint, CustomHero, CustomImage } from './Imprint.Styled'
import heroImage from '../../assets/image/imprint/hero.jpg'
import logo from '../../assets/image/logo.png'
import Container from '../UI/Container/Container'
import Separator from '../UI/Separator/Separator'
import Headline from '../UI/Headline/Headline'
import { useSelector } from 'react-redux'

const Imprint = () => {
  const theme = useSelector((s) => s.theme)
  const translation = useSelector((s) => s.translation)
  return (
    <Layout>
      <CustomHero img={heroImage}>
        <Container>
          <a href="/">
            <CustomImage src={logo} />
          </a>
          <Separator color={theme.color.white} />
        </Container>
      </CustomHero>
      <StyledImprint>
        <Container>
          <Headline
            text={translation.imprint.headline}
            withSeparator={true}
            color={theme.color.blue}
            margin="50px 0px"
          />
          <div className="headline">Wilhelm Hilpert Hausverwaltung GmbH</div>

          <p>Mühlenstraße 35</p>

          <p>10243 Berlin</p>

          <div className="headline">Vertreten durch:</div>

          <p>Geschäftsführer: </p>

          <p>Wilhelm Hilpert</p>

          <p>Mariola Jähn</p>

          <p>Lutz Kutschbach</p>

          <div className="headline">Kontakt</div>
          <p>Tel.: 030 44 38 43 0</p>
          <p>Fax: 030 44 38 43 299</p>
          <p>E-mail: office@hilpert-hausverwaltung.de</p>

          <div className="headline">Redaktionell verantwortlich</div>
          <p>Wilhelm Hilpert </p>
          <p>Mühlenstraße 35</p>

          <p>10243 Berlin</p>

          <div className="headline">Handelsregister:</div>

          <p>Amtsgericht Charlottenburg, Handelsregister B, HRB 65599</p>

          <div className="headline">EU-Streitschlichtung</div>

          <p>
            Die Europäische Kommission stellt eine Plattform zur Online-Streitbeilegung (OS) bereit:
            <a href="https://ec.europa.eu/consumers/odr/" target="_blank" rel="noreferrer">
              https://ec.europa.eu/consumers/odr/
            </a>
            .
          </p>
          <p>Unsere E-Mail-Adresse finden Sie oben im Impressum.</p>

          <div className="headline">Verbraucherstreitbeilegung/Universalschlichtungsstelle</div>
          <p>
            Wir sind nicht bereit oder verpflichtet, an Streitbeilegungsverfahren vor einer
            Verbraucherschlichtungsstelle teilzunehmen.
          </p>
        </Container>
      </StyledImprint>
    </Layout>
  )
}

export default Imprint
