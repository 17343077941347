import { StyledInfo, ItemList, Item, HeadlineItem, Hint } from './Info.Styled'

const Info = () => {
  return (
    <StyledInfo>
      <ItemList large>
        <Item>Bequem: Nur einmal ausfüllen.</Item>
        <Item>Immer termingerecht: Wir buchen die Zahlungen bei Fälligkeit ab.</Item>
        <Item>
          Keine Mahnungen: Sie ersparen sich die Mahngebühren{' '}
          <Hint>(ausreichende Kontodeckung vorausgesetzt).</Hint>
        </Item>
      </ItemList>
      <HeadlineItem>Nutzen Sie unser SEPA-Lastschriftmandat für:</HeadlineItem>
      <ItemList>
        <Item>Wiederkehrende Zahlung (Nur für Wohnmietzahlungen möglich)</Item>
        <Item>Nachforderung aus Betriebs- und Heizkostenabrechnungen</Item>
        <Item>Mahngebühren</Item>
        <Item>Programmierungs- & Beschilderungskosten</Item>
        <Item>Rücklastschriftgebühren</Item>
      </ItemList>
    </StyledInfo>
  )
}

export default Info
