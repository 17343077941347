import {
  StyledCard,
  StyledContent,
  FormWrapper,
  CloseImageWrapper,
  ClickOverlay,
  TakeYourChance,
} from './Card.Styled'
import Text from '../../../UI/Text/Text'
import { useSelector } from 'react-redux'
import Image from '../../../UI/Image/Image'
import borderImageIcon from '../../../../assets/image/career/open-positions-border-image.png'
import Point from './Point/Point'
import closeIcon from '../../../../assets/image/career/close.png'
import Form from '../../Apply/Form/Form'
import { getUUID } from '../../../../utility'

const Card = ({
  uuid,
  title,
  takeYourChance,
  tasks,
  important,
  ourOfferings,
  background,
  color,
  href,
  selected,
  setSelected,
  unselect,
  borderImage = true,
}) => {
  const translation = useSelector((s) => s.translation)
  const theme = useSelector((s) => s.theme)

  const clicked = () => {
    if (href) {
      window.location = href
    } else {
      if (!selected) {
        setSelected()
      }
    }
  }

  return (
    <StyledCard background={background} open={selected}>
      <Text
        text={title}
        color={color || theme.color.blue}
        size={selected ? theme.size.md : theme.size.sm}
        font="bold"
        align="center"
      />
      <Text
        text={translation.career.openPositions.gender}
        color={color || theme.color.blue}
        size={theme.size.sm}
        margin="10px 0px 0px 0px"
        align="center"
      />
      {!selected && <ClickOverlay onClick={clicked} />}
      {selected && (
        <CloseImageWrapper onClick={unselect}>
          <Image src={closeIcon} height="30px" />
        </CloseImageWrapper>
      )}
      {selected && (
        <StyledContent>
          <TakeYourChance>{takeYourChance}</TakeYourChance>
          {Array.isArray(tasks) && (
            <>
              <Text
                text={translation.career.openPositions.ourOfferings}
                color={color || theme.color.blue}
                size={theme.size.sm}
                margin="10px 0px 0px 0px"
                font="bold"
              />
              {ourOfferings.map((text) => (
                <Point text={text} key={getUUID()} />
              ))}
            </>
          )}
          {Array.isArray(tasks) && (
            <>
              <Text
                text={translation.career.openPositions.tasks}
                color={color || theme.color.blue}
                size={theme.size.sm}
                margin="10px 0px 0px 0px"
                font="bold"
              />
              {tasks.map((text) => (
                <Point text={text} key={getUUID()} />
              ))}
            </>
          )}
          {Array.isArray(tasks) && (
            <>
              <Text
                text={translation.career.openPositions.important}
                color={color || theme.color.blue}
                size={theme.size.sm}
                margin="10px 0px 0px 0px"
                font="bold"
              />
              {important.map((text) => (
                <Point text={text} key={getUUID()} />
              ))}
            </>
          )}
          <FormWrapper>
            <Form positionUUID={uuid} />
          </FormWrapper>
        </StyledContent>
      )}
      {borderImage && <Image src={borderImageIcon} width="30px" className="border-image" />}
    </StyledCard>
  )
}

export default Card
