import { StyledHeader, StyledToggle, Navigation, CustomLink, CustomLogo } from './Header.Styled'
import burger from '../../../../assets/image/burger.png'
import { useState } from 'react'
import { useSelector } from 'react-redux'
import logo from '../../../../assets/image/logo.png'

const Header = () => {
  const translation = useSelector((s) => s.translation)
  const [open, setOpen] = useState(false)
  return (
    <StyledHeader>
      <div className="item"></div>
      <div className="item">
        <StyledToggle src={burger} alt="Menu" onClick={() => setOpen(!open)} />
      </div>
      {open && (
        <Navigation>
          <a href="/">
            <CustomLogo src={logo} alt="Wilhelm Hilpert Hausverwaltung GmbH" />
          </a>
          <CustomLink href="/" text={translation.home.headline} />
          <CustomLink href="/estates" text={translation.estates.headline} />
          <CustomLink href="/damage-and-insurance" text={translation.damageAndInsurance.nav} />
          <CustomLink href="/documents" text={translation.documents.headline} />
          {/* <CustomLink href="/team" text={translation.team.headline} /> */}
          <CustomLink href="/career" text={translation.career.headline} />
          <CustomLink href="/contact" text={translation.contact.headline} />
          <CustomLink margin="20px 0 0 0" href="/imprint" text={translation.imprint.headline} />
          <CustomLink href="/data-protection" text={translation.dataProtection.headline} />
        </Navigation>
      )}
    </StyledHeader>
  )
}

export default Header
