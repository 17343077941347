import styled from 'styled-components'
import Hero from '../UI/Hero/Hero'
import Image from '../UI/Image/Image'

export const StyledDamageAndInsurance = styled.div`
  padding: 100px 0px;
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    padding: 50px 0px;
  }
`

export const CustomHero = styled(Hero)`
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
`

export const CustomImage = styled(Image)`
  height: 250px;
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    height: 150px;
  }
`

export const Headline = styled.div``

export const CardWrapper = styled.div`
  display: flex;
  gap: 15px;
  margin-top: 50px;
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    flex-wrap: wrap;
  }
`

export const Card = styled.div`
  box-shadow: ${({ theme }) => theme.shadow};
  padding: 15px;
  border-radius: ${({ theme }) => theme.border.radius};
  width: 100%;
`

export const Name = styled.div`
  font-family: 'bold';
  font-size: 22px;
`
export const Department = styled.div`
  font-family: 'bold';
  font-size: ${({ theme }) => theme.size.sm};
  padding: 5px 0px;
`
export const Company = styled.div`
  font-family: 'bold';
  font-size: 20px;
  padding: 10px 0px;
`
export const Street = styled.div`
  font-size: ${({ theme }) => theme.size.sm};
`
export const City = styled.div`
  font-size: ${({ theme }) => theme.size.sm};
  margin-bottom: 10px;
`

export const Item = styled.div`
  display: flex;
`

export const Label = styled.div`
  font-size: ${({ theme }) => theme.size.sm};
  width: 70px;
`
export const Value = styled.a`
  font-size: ${({ theme }) => theme.size.sm};
  text-decoration: none;
  color: ${({ theme }) => theme.color.black};
`
