import { useSelector } from "react-redux";
import { StyledFooter, Column } from "./Footer.Styled";
import Container from "../../Container/Container";
import Flex from "../../Flex/Flex";
import Text from "../../Text/Text";
import Link from "../../Link/Link";

const Footer = () => {
  const translation = useSelector((s) => s.translation);
  const theme = useSelector((s) => s.theme);

  return (
    <StyledFooter>
      <Container>
        <Flex gap="25px">
          <Column>
            <Text
              text={translation.contact.street}
              size={theme.size.sm}
              color={theme.color.blue}
            />
            <Text
              text={`${translation.contact.postcode} ${translation.contact.city}`}
              size={theme.size.sm}
              color={theme.color.blue}
            />
          </Column>
          <Column>
            <Link
              href="/imprint"
              text={translation.imprint.headline}
              size={theme.size.sm}
              color={theme.color.blue}
            />
          </Column>
          <Column>
            <Link
              href="/data-protection"
              text={translation.dataProtection.headline}
              size={theme.size.sm}
              color={theme.color.blue}
            />
          </Column>
          <Column className="copyright">
            <Text
              color={theme.color.lightblue}
              size={theme.size.sm}
              text={`Wilhelm Hilpert Hausverwaltung GmbH©${new Date().getFullYear()}`}
            />
          </Column>
        </Flex>
      </Container>
    </StyledFooter>
  );
};

export default Footer;
