import fetch, { METHOD } from "../fetch";

export const login = async (password) => {
  const { status, token } = await fetch(METHOD.POST, "/auth/login", {
    password,
  });
  if (status && token) {
    return token;
  }
  return false;
};

export const validateToken = async (token) => {
  const { status, isValid } = await fetch(METHOD.POST, "/auth/validate-token", {
    token,
  });
  return status ? isValid : false;
};
