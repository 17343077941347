import { useSelector } from "react-redux";
import Image from "../../../UI/Image/Image";
import Text from "../../../UI/Text/Text";
import { StyledItem } from "./Item.Styled";
import icon from "../../../../assets/image/career/Icon_Werte.png";

const Item = ({ title, text }) => {
  const theme = useSelector((s) => s.theme);
  return (
    <StyledItem>
      <Image src={icon} height="100px" />
      <Text text={title} align="center" color={theme.color.blue} font="bold" />
      <Text text={text} size={theme.size.sm} align="center" />
    </StyledItem>
  );
};

export default Item;
