import { useSelector } from 'react-redux'
import { StyledDownloadSection, Download, Hint, Address } from './DownloadSection.Styled'

const DownloadSection = ({ link }) => {
  const translation = useSelector((s) => s.translation)
  return (
    <StyledDownloadSection>
      <Download href={link} target="_blank" rel="noreferrer">
        {translation.sepa.download.cta}
      </Download>
      <Hint>{translation.sepa.download.hint}</Hint>
      <Address>{translation.sepa.download.address}</Address>
    </StyledDownloadSection>
  )
}

export default DownloadSection
