import { useState } from 'react'
import { useSelector } from 'react-redux'
import { StyledForm, CustomFlex, CustomAutocomplete, CustomInput } from './Form.Styled'
import { search as searchManager } from '../../../../interface/manager'
import { search as searchCaretakerCompany } from '../../../../interface/caretakerCompany'
import { upsert, remove as removeEstate } from '../../../../interface/estate'
import toast, { TYPE } from '../../../../toast'
import Button from '../../../UI/Button/Button'
import Flex from '../../../UI/Flex/Flex'
import DragAndDrop from '../../../UI/DragAndDrop/DragAndDrop'
import Image from '../../../UI/Image/Image'

const Form = ({ estate }) => {
  const translation = useSelector((s) => s.translation)
  const theme = useSelector((s) => s.theme)
  const token = useSelector((s) => s.userToken)
  const [deleted, setDeleted] = useState(false)
  const init = {
    number: '',
    city: '',
    street: '',
    postcode: '',
    managerList: [],
    caretakerCompany: '',
    accountingPerson: '',
    emergencyNumber: '',
    imageKey: '',
  }
  const [data, setData] = useState(estate ? estate : init)

  const save = async () => {
    if (data.caretakerCompany) {
      data.caretakerCompanyUUID = data.caretakerCompany.uuid
    }
    if (Array.isArray(data.managerList)) {
      data.managerUUIDList = data.managerList.map((manager) => manager.uuid)
    }

    const status = await upsert(token, data)
    if (status) {
      toast(TYPE.SUCCESS, translation.admin.estates.form.success)
      if (estate && !estate.uuid) {
        setData(init)
      }
    } else {
      toast(TYPE.ERROR, translation.admin.estates.form.error)
    }
  }

  const update = (prop) => (value) => setData({ ...data, [prop]: value })

  const remove = async () => {
    const status = await removeEstate(token, estate.uuid)
    if (status) {
      setDeleted(true)
    } else {
      toast(TYPE.ERROR, translation.admin.estates.form.removeError)
    }
  }

  if (deleted) return <></>
  return (
    <StyledForm onSubmit={save}>
      <CustomFlex>
        <CustomInput
          value={data.number}
          onChange={update('number')}
          placeholder={translation.admin.estates.form.number}
        />
        <CustomInput
          value={data.believerId}
          onChange={update('believerId')}
          placeholder={translation.admin.estates.form.believerId}
        />
        <CustomInput
          value={data.iban}
          onChange={update('iban')}
          placeholder={translation.admin.estates.form.iban}
        />
        <CustomInput
          value={data.city}
          onChange={update('city')}
          placeholder={translation.admin.estates.form.city}
        />
        <CustomInput
          value={data.street}
          onChange={update('street')}
          placeholder={translation.admin.estates.form.street}
        />
        <CustomInput
          value={data.postcode}
          onChange={update('postcode')}
          placeholder={translation.admin.estates.form.postcode}
        />
        <CustomInput
          value={data.accountingPerson}
          onChange={update('accountingPerson')}
          placeholder={translation.admin.estates.form.accountingPerson}
        />
        <CustomInput
          value={data.emergencyNumber}
          onChange={update('emergencyNumber')}
          placeholder={translation.admin.estates.form.emergencyNumber}
        />
        <CustomAutocomplete
          multiple
          value={data.managerList}
          onChange={update('managerList')}
          placeholder={translation.admin.estates.form.manager}
          searchInterface={searchManager}
          renderFunction={(data) => `${data.firstname} ${data.lastname}`}
        />
        <CustomAutocomplete
          value={data.caretakerCompany}
          onChange={update('caretakerCompany')}
          placeholder={translation.generic.caretakerCompany}
          searchInterface={searchCaretakerCompany}
          renderFunction={(data) => data.name}
        />
        <DragAndDrop
          background={theme.color.white}
          width="100%"
          title={translation.admin.estates.form.dragAndDrop.title}
          mobileTitle={translation.admin.estates.form.dragAndDrop.mobileTitle}
          text={translation.admin.estates.form.dragAndDrop.text}
          maxFileSizeExceededToast={translation.admin.estates.form.dragAndDrop.error.fileSize}
          invalidFileExtensionToast={translation.admin.estates.form.dragAndDrop.error.fileFormat}
          onFile={update('image')}
          multiple={false}
          value={data.image}
          maxFileSizeInBytes={10000000}
          allowedFileExtensions={['jpg', 'jpeg', 'png']}
        />
        {data.imageURL && <Image src={data.imageURL} maxWidth="100%" height="200px" />}
      </CustomFlex>
      <Flex>
        {estate && estate.uuid && (
          <Button
            margin="25px auto 0px auto"
            onClick={remove}
            text={translation.admin.estates.form.removeCTA}
            backgroundColor={theme.color.red}
          />
        )}
        <Button
          margin="25px auto 0px auto"
          type="submit"
          text={translation.admin.estates.form.cta}
        />
      </Flex>
    </StyledForm>
  )
}

export default Form
