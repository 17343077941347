import { useSelector } from 'react-redux'
import Container from '../../UI/Container/Container'
import { StyledSearch } from './Search.Styled'
import Text from '../../UI/Text/Text'
import Autocomplete from '../../UI/Autocomplete/Autocomplete'
import { search as searchEstate } from '../../../interface/estate'
import { useNavigate } from 'react-router-dom'
import summarize from '../../Estates/helper/summarize'
import labelGenerator from '../../Estates/helper/labelGenerator'

const Search = () => {
  const translation = useSelector((s) => s.translation)
  const theme = useSelector((s) => s.theme)
  const navigate = useNavigate()

  const redirect = (estate) => {
    navigate(`/estates?open=${estate.uuid}`)
  }

  const search = async (token, searchString) => {
    if (searchString.length >= 4) {
      const results = await searchEstate(searchString)
      const summarizedStreets = summarize(results)
      return Object.keys(summarizedStreets).reduce((acc, streetName) => {
        const item = summarizedStreets[streetName]
        acc.push({
          label: labelGenerator(item),
          uuid: item[0].uuid,
        })
        return acc
      }, [])
    } else {
      return 'no-show'
    }
  }

  return (
    <StyledSearch>
      <Container>
        <Text text={translation.home.search.title} color={theme.color.blue} font="bold" />
        <Text text={translation.home.search.text} color={theme.color.blue} margin="20px 0" />
        <Text text={translation.home.search.inputTitle} color={theme.color.blue} font="thin" />
        {/* <Form onSubmit={redirect}> */}
        <Autocomplete
          margin="10px 0 0 0"
          backgroundColor={theme.color.blue}
          color={theme.color.white}
          size={theme.size.md}
          onChange={redirect}
          searchInterface={search}
          renderFunction={(data) => `${data.label}`}
        />
        {/* <CustomButton
            text={translation.home.search.cta}
            type="submit"
            margin="40px auto"
          /> */}
        {/* </Form> */}
      </Container>
    </StyledSearch>
  )
}

export default Search
