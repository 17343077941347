import { useSelector } from "react-redux";
import Image from "../../../UI/Image/Image";
import Text from "../../../UI/Text/Text";
import { StyledItem } from "./Item.Styled";

const Item = ({ img, title, title2, text }) => {
  const theme = useSelector((s) => s.theme);
  return (
    <StyledItem>
      <Image src={img} height="100px" />
      <Text
        text={title}
        align="center"
        color={theme.color.blue}
        font="bold"
        margin={title2 ? "35px 0px 0px 0px" : "35px 0px 25px 0px"}
      />
      {title2 && (
        <Text
          text={title2}
          align="center"
          color={theme.color.blue}
          font="bold"
          margin="0px 0px 25px 0px"
        />
      )}
      <Text text={text} size={theme.size.sm} align="center" />
    </StyledItem>
  );
};

export default Item;
