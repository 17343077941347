import styled from "styled-components";

export const StyledTextarea = styled.textarea`
  border: none;
  color: ${({ theme }) => theme.color.black};
  background-color: ${({ theme }) => theme.color.white};
  padding: 10px 15px;
  font-size: ${({ theme }) => theme.size.sm};
  margin: ${({ margin }) => margin || "0px"};
  border-radius: ${({ theme }) => theme.border.radius};
  display: block;
  width: 100%;
  outline: none;
  resize: none;
  box-sizing: border-box;
  ::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: ${({ theme }) => theme.color.black};
    opacity: 1; /* Firefox */
  }

  :-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: ${({ theme }) => theme.color.black};
  }

  ::-ms-input-placeholder {
    /* Microsoft Edge */
    color: ${({ theme }) => theme.color.black};
  }
`;
