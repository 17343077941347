import styled from 'styled-components'

export const StyledInfo = styled.div``

export const ItemList = styled.ul`
  margin-left: 17.5px;
  &:first-child {
    margin-top: 25px;
  }
  li {
    font-size: ${({ large, theme }) => (large ? '22px' : theme.size.sm)};
  }
`
export const Item = styled.li``
export const HeadlineItem = styled.div`
  font-size: ${({ theme }) => theme.size.sm};
  margin-top: 25px;
  margin-left: 0px;
`

export const Hint = styled.span`
  font-size: 14px;
`
