import { useSelector } from "react-redux";
import Flex from "../../UI/Flex/Flex";
import Headline from "../../UI/Headline/Headline";
import { StyledOurValues } from "./OurValues.Styled";
import Item from "./Item/Item";
import Container from "../../UI/Container/Container";
import { getUUID } from "../../../utility";

const OurValues = () => {
  const translation = useSelector((s) => s.translation);
  const theme = useSelector((s) => s.theme);

  return (
    <StyledOurValues>
      <Headline
        text={translation.career.ourValues.headline}
        color={theme.color.white}
        withSeparator={true}
      />
      <Container>
        <Flex gap="20px" margin="50px 0px 0px 0px">
          {translation.career.ourValues.items.map(({ title, text }) => (
            <Item title={title} text={text} key={getUUID()} />
          ))}
        </Flex>
      </Container>
    </StyledOurValues>
  );
};

export default OurValues;
