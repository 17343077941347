import styled from 'styled-components'
import Button from '../../../UI/Button/Button'

export const StyledEstateSearch = styled.div`
  margin-top: 25px;
`

export const CustomButton = styled(Button)`
  box-shadow: ${({ theme }) => theme.shadow};
  background-color: ${({ theme }) => theme.color.white};
  color: ${({ theme }) => theme.color.blue};
  padding: 15px;
  margin-left: auto;
  margin-right: auto;
  display: block;
`
