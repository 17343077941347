import { StyledLayout } from "./Layout.Styled";
import Header from "./Header/Header";
import Footer from "./Footer/Footer";
import ResponsiveState from "./ResponsiveState/ResponsiveState";
import Secure from "./Secure/Secure";

const Layout = ({ children, className, authRequired }) => {
  return (
    <StyledLayout className={className}>
      <Secure authRequired={authRequired}>
        <ResponsiveState />
        <Header />
        {children}
        <Footer />
      </Secure>
    </StyledLayout>
  );
};

export default Layout;
