import { useSelector } from "react-redux";
import Container from "../../UI/Container/Container";
import Headline from "../../UI/Headline/Headline";
import { StyledTimeline } from "./Timeline.Styled";
import Point from "./Point/Point";
import { getUUID } from "../../../utility";
import Flex from "../../UI/Flex/Flex";

const Timeline = () => {
  const translation = useSelector((s) => s.translation);
  const theme = useSelector((s) => s.theme);
  return (
    <StyledTimeline>
      <Container>
        <Headline
          text={translation.career.timeline.headline}
          color={theme.color.white}
          withSeparator={true}
          margin="0 0 75px 0"
        />
        <Flex gap="15px" justify={"space-between"}>
          {translation.career.timeline.points.map((point, i) => (
            <Point number={i} text={point} key={getUUID()} />
          ))}
        </Flex>
      </Container>
    </StyledTimeline>
  );
};

export default Timeline;
