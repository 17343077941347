import * as types from "./type/type";
import * as translations from "./translations";
import * as responsiveState from "./responsiveState";
import * as user from "./user";
import defaultState from "./defaultState";

function reducer(state = defaultState, action) {
  switch (action.type) {
    case types.SET_TRANSLATION:
      return translations.set(state, action);
    case types.SET_RESPONSIVE_STATE:
      return responsiveState.set(state, action);
    case types.SET_USER_TOKEN:
      return user.setToken(state, action);
    default:
      return state;
  }
}

export default reducer;
