import { useState } from 'react'
import { useSelector } from 'react-redux'
import { StyledForm } from './Form.Styled'
import { upsert, remove as removePosition } from '../../../../interface/position'
import toast, { TYPE } from '../../../../toast'
import Button from '../../../UI/Button/Button'
import Flex from '../../../UI/Flex/Flex'
import Input from '../../../UI/Input/Input'
import Textarea from '../../../UI/Textarea/Textarea'

const Form = ({ position }) => {
  const translation = useSelector((s) => s.translation)
  const theme = useSelector((s) => s.theme)
  const token = useSelector((s) => s.userToken)
  const [deleted, setDeleted] = useState(false)
  const [data, setData] = useState(
    position
      ? position
      : {
          title: '',
          takeYourChance: '',
          tasks: '',
          important: '',
          ourOfferings: '',
        },
  )

  const save = async () => {
    const status = await upsert(token, data)
    if (status) {
      toast(TYPE.SUCCESS, translation.admin.positions.form.success)
      if (position && position.uuid) {
        setData({})
      }
    } else {
      toast(TYPE.ERROR, translation.admin.positions.form.error)
    }
  }

  const update = (prop) => (value) => setData({ ...data, [prop]: value })

  const remove = async () => {
    const status = await removePosition(token, position.uuid)
    if (status) {
      setDeleted(true)
    } else {
      toast(TYPE.ERROR, translation.admin.positions.form.removeError)
    }
  }

  if (deleted) return <></>
  return (
    <StyledForm onSubmit={save}>
      <Input
        value={data.title}
        onChange={update('title')}
        placeholder={translation.admin.positions.form.title}
        margin="10px 0 0 0"
      />
      <Input
        value={data.takeYourChance}
        onChange={update('takeYourChance')}
        placeholder={translation.admin.positions.form.takeYourChance}
        margin="10px 0 0 0"
      />
      <Textarea
        margin="10px 0 0 0"
        value={data.tasks}
        onChange={update('tasks')}
        placeholder={translation.admin.positions.form.tasks}
      />
      <Textarea
        margin="10px 0 0 0"
        value={data.important}
        onChange={update('important')}
        placeholder={translation.admin.positions.form.important}
      />
      <Textarea
        margin="10px 0 0 0"
        value={data.ourOfferings}
        onChange={update('ourOfferings')}
        placeholder={translation.admin.positions.form.ourOfferings}
      />
      <Flex>
        {position && position.uuid && (
          <Button
            margin="25px auto 0px auto"
            onClick={remove}
            text={translation.admin.positions.form.removeCTA}
            backgroundColor={theme.color.red}
          />
        )}
        <Button
          margin="25px auto 0px auto"
          type="submit"
          text={translation.admin.positions.form.cta}
        />
      </Flex>
    </StyledForm>
  )
}

export default Form
