import styled from 'styled-components'
import Hero from '../UI/Hero/Hero'
import Image from '../UI/Image/Image'

export const StyledDocuments = styled.div``

export const CustomHero = styled(Hero)`
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
`

export const CustomImage = styled(Image)`
  height: 250px;
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    height: 150px;
  }
`
