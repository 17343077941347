import {
  StyledDamageAndInsurance,
  CustomHero,
  CustomImage,
  CardWrapper,
  Card,
  Name,
  Department,
  Company,
  Street,
  City,
  Item,
  Label,
  Value,
} from './DamageAndInsurance.Styled'
import Layout from '../UI/Layout/Layout'
import Container from '../UI/Container/Container'
import Separator from '../UI/Separator/Separator'
import Headline from '../UI/Headline/Headline'
import heroImage from '../../assets/image/damageAndInsurance/hero.jpg'
import logo from '../../assets/image/logo.png'
import { useSelector } from 'react-redux'
import { StyledContainer } from '../UI/Container/Container.Styled'
import { getUUID } from '../../utility'

const DamageAndInsurance = () => {
  const theme = useSelector((s) => s.theme)
  const translation = useSelector((s) => s.translation)

  return (
    <Layout>
      <CustomHero img={heroImage}>
        <Container>
          <a href="/">
            <CustomImage src={logo} />
          </a>
          <Separator color={theme.color.white} />
        </Container>
      </CustomHero>
      <StyledContainer>
        <StyledDamageAndInsurance>
          <Headline
            text={translation.damageAndInsurance.headline}
            color={theme.color.blue}
            withSeparator={true}
            sizeMobile={theme.size.sm}
          />
          <CardWrapper>
            {translation.damageAndInsurance.cards.map((c) => (
              <Card key={getUUID()}>
                <Name>{c.name}</Name>
                <Department>{c.department}</Department>
                <Company>{c.company}</Company>
                <Street>{c.street}</Street>
                <City>{c.city}</City>
                <Item>
                  <Label>{translation.generic.phone}</Label>
                  <Value href={`tel:${c.phone}`}>{c.phone}</Value>
                </Item>
                <Item>
                  <Label>{translation.generic.fax}</Label>
                  <Value>{c.fax}</Value>
                </Item>
                <Item>
                  <Label>{translation.generic.mobile}</Label>
                  <Value href={`tel:${c.mobile}`}>{c.mobile}</Value>
                </Item>
                {c.mail.map((m) => (
                  <Item key={getUUID()}>
                    <Label>{translation.generic.mail}</Label>
                    <Value href={`mailto:${m}`}>{m}</Value>
                  </Item>
                ))}
              </Card>
            ))}
          </CardWrapper>
        </StyledDamageAndInsurance>
      </StyledContainer>
    </Layout>
  )
}

export default DamageAndInsurance
