import { StyledLoading, Spinner } from './Loading.Styled'
import loader from '../../../../assets/image/sepa/loader.gif'

const Loading = () => {
  return (
    <StyledLoading>
      <Spinner src={loader} />
    </StyledLoading>
  )
}

export default Loading
