import { StyledDocuments, CustomHero, CustomImage } from './Documents.Styled'
import Layout from '../UI/Layout/Layout'
import Container from '../UI/Container/Container'
import Separator from '../UI/Separator/Separator'
import heroImage from '../../assets/image/documents/hero.jpg'
import logo from '../../assets/image/logo.png'
import { useSelector } from 'react-redux'
import Sepa from './Sepa/Sepa'

const Documents = () => {
  const theme = useSelector((s) => s.theme)

  return (
    <Layout>
      <StyledDocuments>
        <CustomHero img={heroImage}>
          <Container>
            <a href="/">
              <CustomImage src={logo} />
            </a>
            <Separator color={theme.color.white} />
          </Container>
        </CustomHero>
        <Sepa />
      </StyledDocuments>
    </Layout>
  )
}

export default Documents
