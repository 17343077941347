import styled from 'styled-components'

export const StyledInput = styled.input`
  border: ${({ border }) => border || 'none'};
  color: ${({ theme, color }) => color || theme.color.black};
  background-color: ${({ theme, backgroundColor }) => backgroundColor || theme.color.white};
  padding: 10px 15px;
  font-size: ${({ theme, size }) => size || theme.size.sm};
  margin: ${({ margin }) => margin || '0px'};
  border-radius: ${({ theme }) => theme.border.radius};
  display: block;
  width: 100%;
  outline: none;
  box-sizing: border-box;
  ::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: ${({ theme, color }) => color || theme.color.black};
    opacity: 1; /* Firefox */
  }

  :-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: ${({ theme, color }) => color || theme.color.black};
  }

  ::-ms-input-placeholder {
    /* Microsoft Edge */
    color: ${({ theme, color }) => color || theme.color.black};
  }
`
