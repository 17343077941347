import styled from 'styled-components'
import Button from '../../../UI/Button/Button'
import Input from '../../../UI/Input/Input'
import DragAndDrop from '../../../UI/DragAndDrop/DragAndDrop'

export const StyledContactModal = styled.div``

export const CTAWrapper = styled.div`
  text-align: right;
  margin-top: 15px;
`

export const SendCTA = styled(Button)`
  font-size: ${({ theme }) => theme.size.sm};
`

export const CustomInput = styled(Input)`
  border: thin solid ${({ theme }) => theme.color.blue};
  padding: 10px;
  margin-bottom: 15px;
`

export const CustomTextarea = styled.textarea`
  resize: none;
  border: thin solid ${({ theme }) => theme.color.blue};
  border-radius: ${({ theme }) => theme.border.radius};
  font-size: ${({ theme }) => theme.size.sm};
  padding: 10px;
  display: block;
  width: 100%;
  box-sizing: border-box;
  outline: none;
  height: 150px;
  ::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: ${({ theme, color }) => color || theme.color.black};
    opacity: 1; /* Firefox */
  }

  :-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: ${({ theme, color }) => color || theme.color.black};
  }

  ::-ms-input-placeholder {
    /* Microsoft Edge */
    color: ${({ theme, color }) => color || theme.color.black};
  }
`

export const CustomDragAndDrop = styled(DragAndDrop)`
  box-sizing: border-box;
  border: thin solid ${({ theme }) => theme.color.blue};
  border-radius: ${({ theme }) => theme.border.radius};
  margin-top: 15px;
`
