import Container from '../../UI/Container/Container'
import {
  StyledSepa,
  Headline,
  InputSplit,
  LabelWrapper,
  InputWrapper,
  Label,
  CustomInput,
  Separator,
  CheckboxTitle,
  CheckboxWrapper,
  CheckboxInput,
  CheckboxLabel,
  Hint,
  CTAWrapper,
  CTA,
} from './Sepa.Styled'
import { generate } from '../../../interface/sepa'
import toast, { TYPE } from '../../../toast'
import EstateSearch from './EstateSearch/EstateSearch'
import { get } from '../../../interface/estate'
import DownloadSection from './DownloadSection/DownloadSection'
import NotAvailable from './NotAvailable/NotAvailable'
import Info from './Info/Info'
import Loading from './Loading/Loading'
const { useState } = require('react')
const { useSelector } = require('react-redux')

const Sepa = () => {
  const [data, setData] = useState({})
  const translation = useSelector((s) => s.translation)
  const [downloadLink, setDownloadLink] = useState()
  const [processing, setProcessing] = useState(false)

  const updateProp = (prop) => (value) => setData({ ...data, [prop]: value })

  const send = async (e) => {
    e.preventDefault()
    if (data.estateUUID) {
      setProcessing(true)
      const publicPath = await generate(data)
      if (publicPath) {
        setDownloadLink(publicPath)
        setData({})
        setProcessing(false)
      } else {
        toast(TYPE.ERROR, translation.sepa.error.generate)
      }
    } else {
      toast(TYPE.ERROR, translation.sepa.error.estateMissing)
    }
  }

  const updateEstate = async (e) => {
    const estate = await get(e.uuid)
    if (estate) {
      setData({
        ...data,
        estateUUID: estate.uuid,
        street: estate.street,
        accountStreet: estate.street,
        postcodeAndCity: `${estate.postcode} ${estate.city}`,
        accountPostcodeAndCity: `${estate.postcode} ${estate.city}`,
        believerIdAvailable: estate.believerIdAvailable,
      })
    }
  }

  const isSepaAvailable = () => {
    if (data.believerIdAvailable === 'undefined') return null
    return data.believerIdAvailable
  }

  return (
    <StyledSepa>
      <Container>
        <Headline>{translation.sepa.headline}</Headline>
        {downloadLink && <DownloadSection link={downloadLink} />}
        {!downloadLink && <Info />}
        {!downloadLink && (
          <>
            <EstateSearch callback={updateEstate} />
            {isSepaAvailable() === false && <NotAvailable />}
            {isSepaAvailable() === true && (
              <form onSubmit={send}>
                <InputSplit>
                  <LabelWrapper>
                    <Label>{translation.sepa.name}</Label>
                  </LabelWrapper>
                  <InputWrapper>
                    <CustomInput
                      value={data.name}
                      onChange={updateProp('name')}
                      placeholder={translation.sepa.namePlaceholder}
                      required
                    />
                  </InputWrapper>
                </InputSplit>
                <InputSplit>
                  <LabelWrapper>
                    <Label>{translation.sepa.contractNumber}</Label>
                  </LabelWrapper>
                  <InputWrapper>
                    <CustomInput
                      value={data.contractNumber}
                      onChange={updateProp('contractNumber')}
                      required
                    />
                  </InputWrapper>
                </InputSplit>
                {/* <InputSplit>
            <LabelWrapper>
              <Label>{translation.sepa.streetAndNumber}</Label>
            </LabelWrapper>
            <InputWrapper>
              <CustomInput value={data.street} onChange={updateProp('street')} required />
            </InputWrapper>
          </InputSplit>
          <InputSplit>
            <LabelWrapper>
              <Label>{translation.sepa.postcodeAndCity}</Label>
            </LabelWrapper>
            <InputWrapper>
              <CustomInput
                value={data.postcodeAndCity}
                onChange={updateProp('postcodeAndCity')}
                required
              />
            </InputWrapper>
          </InputSplit> */}
                <InputSplit>
                  <LabelWrapper>
                    <Label>{translation.sepa.flatNumber}</Label>
                  </LabelWrapper>
                  <InputWrapper>
                    <CustomInput value={data.flatNumber} onChange={updateProp('flatNumber')} />
                  </InputWrapper>
                </InputSplit>
                <Separator />
                <InputSplit>
                  <LabelWrapper>
                    <Label>{translation.sepa.accountOwner}</Label>
                  </LabelWrapper>
                  <InputWrapper>
                    <CustomInput
                      value={data.accountOwner}
                      onChange={updateProp('accountOwner')}
                      required
                    />
                  </InputWrapper>
                </InputSplit>
                <InputSplit>
                  <LabelWrapper>
                    <Label>{translation.sepa.accountStreetAndNumber}</Label>
                  </LabelWrapper>
                  <InputWrapper>
                    <CustomInput
                      value={data.accountStreet}
                      onChange={updateProp('accountStreet')}
                      required
                    />
                  </InputWrapper>
                </InputSplit>
                <InputSplit>
                  <LabelWrapper>
                    <Label>{translation.sepa.accountPostcodeAndCity}</Label>
                  </LabelWrapper>
                  <InputWrapper>
                    <CustomInput
                      value={data.accountPostcodeAndCity}
                      onChange={updateProp('accountPostcodeAndCity')}
                      required
                    />
                  </InputWrapper>
                </InputSplit>
                <InputSplit>
                  <LabelWrapper>
                    <Label>{translation.sepa.iban}</Label>
                  </LabelWrapper>
                  <InputWrapper>
                    <CustomInput value={data.iban} onChange={updateProp('iban')} required />
                  </InputWrapper>
                </InputSplit>
                <InputSplit>
                  <LabelWrapper>
                    <Label>{translation.sepa.bic}</Label>
                  </LabelWrapper>
                  <InputWrapper>
                    <CustomInput value={data.bic} onChange={updateProp('bic')} required />
                  </InputWrapper>
                </InputSplit>
                <InputSplit>
                  <LabelWrapper>
                    <Label>{translation.sepa.bank}</Label>
                  </LabelWrapper>
                  <InputWrapper>
                    <CustomInput value={data.bank} onChange={updateProp('bank')} required />
                  </InputWrapper>
                </InputSplit>
                <InputSplit>
                  <LabelWrapper>
                    <Label>{translation.sepa.validFrom}</Label>
                  </LabelWrapper>
                  <InputWrapper>
                    <CustomInput
                      value={data.validFrom}
                      onChange={updateProp('validFrom')}
                      required
                      type="date"
                    />
                  </InputWrapper>
                </InputSplit>
                <CheckboxTitle>{translation.sepa.checkboxTitle}</CheckboxTitle>
                <CheckboxWrapper>
                  <CheckboxInput
                    type="checkbox"
                    id="readLegalNote"
                    checked={data.readLegalNote || false}
                    onChange={(e) => updateProp('readLegalNote')(e.target.checked)}
                    required
                  />
                  <CheckboxLabel htmlFor="readLegalNote">
                    {translation.sepa.checkboxText}
                  </CheckboxLabel>
                </CheckboxWrapper>
                <Hint>{translation.sepa.hint1}</Hint>
                <Hint>{translation.sepa.hint2}</Hint>
                <CTAWrapper>
                  {!processing && <CTA text={translation.sepa.sendCTA} type="submit" />}
                  {processing && <Loading />}
                </CTAWrapper>
              </form>
            )}
          </>
        )}
      </Container>
    </StyledSepa>
  )
}

export default Sepa
