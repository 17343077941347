import { useState } from "react";
import { useSelector } from "react-redux";
import { StyledForm, CustomFlex, CustomInput } from "./Form.Styled";
import { upsert, remove as removeManager } from "../../../../interface/manager";
import toast, { TYPE } from "../../../../toast";
import Button from "../../../UI/Button/Button";
import Flex from "../../../UI/Flex/Flex";

const Form = ({ manager }) => {
  const translation = useSelector((s) => s.translation);
  const theme = useSelector((s) => s.theme);
  const token = useSelector((s) => s.userToken);
  const [deleted, setDeleted] = useState(false);
  const [data, setData] = useState(
    manager
      ? manager
      : {
          salutation: "",
          firstname: "",
          lastname: "",
          phone: "",
          mail: "",
        }
  );

  const save = async () => {
    const status = await upsert(token, data);
    if (status) {
      toast(TYPE.SUCCESS, translation.admin.managers.form.success);
      if (manager && manager.uuid) {
        setData({});
      }
    } else {
      toast(TYPE.ERROR, translation.admin.managers.form.error);
    }
  };

  const update = (prop) => (value) => setData({ ...data, [prop]: value });

  const remove = async () => {
    const status = await removeManager(token, manager.uuid);
    if (status) {
      setDeleted(true);
    } else {
      toast(TYPE.ERROR, translation.admin.managers.form.removeError);
    }
  };

  if (deleted) return <></>;
  return (
    <StyledForm onSubmit={save}>
      <CustomFlex>
        <CustomInput
          value={data.salutation}
          onChange={update("salutation")}
          placeholder={translation.generic.salutation}
        />
        <CustomInput
          value={data.firstname}
          onChange={update("firstname")}
          placeholder={translation.generic.firstname}
        />
        <CustomInput
          value={data.lastname}
          onChange={update("lastname")}
          placeholder={translation.generic.lastname}
        />
        <CustomInput
          value={data.phone}
          onChange={update("phone")}
          placeholder={translation.generic.phone}
        />
        <CustomInput
          value={data.mail}
          onChange={update("mail")}
          placeholder={translation.generic.mail}
        />
      </CustomFlex>
      <Flex>
        {manager && manager.uuid && (
          <Button
            margin="25px auto 0px auto"
            onClick={remove}
            text={translation.admin.managers.form.deleteCTA}
            backgroundColor={theme.color.red}
          />
        )}
        <Button
          margin="25px auto 0px auto"
          type="submit"
          text={translation.admin.managers.form.cta}
        />
      </Flex>
    </StyledForm>
  );
};

export default Form;
