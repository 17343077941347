import styled from "styled-components";

export const StyledNav = styled.div`
  position: fixed;
  left: 50px;
  bottom: 50px;
  width: 50%;
  display: flex;
  gap: 25px;
  z-index: 999;
  background-color: ${({ theme }) => theme.color.white};
  padding: 20px;
  justify-content: space-between;
`;
