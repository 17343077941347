import { useState } from "react";
import { useSelector } from "react-redux";
import { StyledForm, CustomFlex, CustomInput } from "./Form.Styled";
import {
  upsert,
  remove as removeCaretakerCompany,
} from "../../../../interface/caretakerCompany";
import toast, { TYPE } from "../../../../toast";
import Button from "../../../UI/Button/Button";
import Flex from "../../../UI/Flex/Flex";

const Form = ({ caretakerCompany }) => {
  const translation = useSelector((s) => s.translation);
  const theme = useSelector((s) => s.theme);
  const token = useSelector((s) => s.userToken);
  const [deleted, setDeleted] = useState(false);
  const [data, setData] = useState(
    caretakerCompany
      ? caretakerCompany
      : {
          name: "",
          street: "",
          postcode: "",
          city: "",
          phone: "",
          fax: "",
          mail: "",
        }
  );

  const save = async () => {
    const status = await upsert(token, data);
    if (status) {
      toast(TYPE.SUCCESS, translation.admin.caretakerCompany.form.success);
    } else {
      toast(TYPE.ERROR, translation.admin.caretakerCompany.form.error);
    }
  };

  const update = (prop) => (value) => setData({ ...data, [prop]: value });

  const remove = async () => {
    const status = await removeCaretakerCompany(token, caretakerCompany.uuid);
    if (status) {
      setDeleted(true);
    } else {
      toast(TYPE.ERROR, translation.admin.caretakerCompany.form.removeError);
    }
  };

  if (deleted) return <></>;
  return (
    <StyledForm onSubmit={save}>
      <CustomFlex>
        <CustomInput
          value={data.name}
          onChange={update("name")}
          placeholder={translation.admin.caretakerCompany.form.name}
        />
        <CustomInput
          value={data.street}
          onChange={update("street")}
          placeholder={translation.generic.street}
        />
        <CustomInput
          value={data.postcode}
          onChange={update("postcode")}
          placeholder={translation.generic.postcode}
        />
        <CustomInput
          value={data.city}
          onChange={update("city")}
          placeholder={translation.generic.city}
        />
        <CustomInput
          value={data.phone}
          onChange={update("phone")}
          placeholder={translation.generic.phone}
        />
        <CustomInput
          value={data.fax}
          onChange={update("fax")}
          placeholder={translation.generic.fax}
        />
        <CustomInput
          value={data.mail}
          onChange={update("mail")}
          placeholder={translation.generic.mail}
        />
      </CustomFlex>
      <Flex>
        {caretakerCompany && caretakerCompany.uuid && (
          <Button
            margin="25px auto 0px auto"
            onClick={remove}
            text={translation.admin.caretakerCompany.form.deleteCTA}
            backgroundColor={theme.color.red}
          />
        )}
        <Button
          margin="25px auto 0px auto"
          type="submit"
          text={translation.admin.caretakerCompany.form.cta}
        />
      </Flex>
    </StyledForm>
  );
};

export default Form;
