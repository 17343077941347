import { useEffect, useState } from "react";
import { getUUID } from "../../../utility.js";
import Flex from "../Flex/Flex.js";
import { StyledAccordion } from "./Accordion.Styled.js";
import Item from "./Item/Item";

const Accordion = ({
  items,
  headerBackgroundColor,
  headerColor,
  bodyBackgroundColor,
  borderRadius,
  bodyColor,
  className,
  width,
  margin,
  gap = "10px",
  initOpenIndex = null,
}) => {
  const [openIndex, setOpenIndex] = useState(initOpenIndex);

  useEffect(() => {
    if (Number.isInteger(initOpenIndex) && initOpenIndex >= 0) {
      const element = document.getElementById(initOpenIndex);
      element.scrollIntoView();
    }
  }, [initOpenIndex]);

  const toggle = (index) => () =>
    setOpenIndex(openIndex === index ? null : index);

  return (
    <StyledAccordion className={className} margin={margin}>
      <Flex gap={gap}>
        {items.map(({ title, content }, i) => (
          <Item
            width={width}
            open={openIndex === i}
            toggle={toggle(i)}
            title={title}
            content={content}
            headerBackgroundColor={headerBackgroundColor}
            headerColor={headerColor}
            bodyBackgroundColor={bodyBackgroundColor}
            bodyColor={bodyColor}
            borderRadius={borderRadius}
            key={getUUID()}
            id={i}
          />
        ))}
      </Flex>
    </StyledAccordion>
  );
};

export default Accordion;
