import { useSelector } from 'react-redux'
import { StyledEstateSearch } from './EstateSearch.Styled'
import Text from '../../../UI/Text/Text'
import Autocomplete from '../../../UI/Autocomplete/Autocomplete'
import { search as searchEstate } from '../../../../interface/estate'
import summarize from '../../../Estates/helper/summarize'
import labelGenerator from '../../../Estates/helper/labelGenerator'

const EstateSearch = ({ callback }) => {
  const translation = useSelector((s) => s.translation)
  const theme = useSelector((s) => s.theme)

  const search = async (token, searchString) => {
    if (searchString.length >= 4) {
      const results = await searchEstate(searchString)
      const summarizedStreets = summarize(results)
      return Object.keys(summarizedStreets).reduce((acc, streetName) => {
        const item = summarizedStreets[streetName]
        acc.push({
          label: labelGenerator(item),
          uuid: item[0].uuid,
        })
        return acc
      }, [])
    } else {
      return 'no-show'
    }
  }

  return (
    <StyledEstateSearch>
      <Text text={translation.sepa.estateSearch.title} size={theme.size.sm} margin="0 0 5px 0" />
      <Autocomplete
        size={theme.size.sm}
        onChange={callback}
        searchInterface={search}
        renderFunction={(data) => `${data.label}`}
        border={`thin solid ${theme.color.blue}`}
      />
    </StyledEstateSearch>
  )
}

export default EstateSearch
