import Layout from "../../UI/Layout/Layout";
import { StyledPositions } from "./Positions.Styled";
import { list } from "../../../interface/position";
import { useState } from "react";
import { useEffect } from "react";
import Headline from "../../UI/Headline/Headline";
import { useSelector } from "react-redux";
import Container from "../../UI/Container/Container";
import Form from "./Form/Form";
import Nav from "../Nav/Nav";
import List from "./List/List";

const Positions = () => {
  const translation = useSelector((s) => s.translation);
  const theme = useSelector((s) => s.theme);
  const [positions, setPositions] = useState(null);

  useEffect(() => {
    const fetch = async () => {
      const positions = await list();
      setPositions(
        positions.map((p) => ({
          ...p,
          tasks: p.tasks.join("\n"),
          important: p.important.join("\n"),
          ourOfferings: p.ourOfferings.join("\n"),
        }))
      );
    };

    if (!positions) {
      fetch();
    }
  }, [positions]);

  return (
    <Layout authRequired={true}>
      <Nav />
      <StyledPositions>
        <Container>
          <Headline
            text={translation.admin.positions.create.headline}
            withSeparator={true}
            color={theme.color.white}
          />
          <Form />
          {positions && (
            <>
              <Headline
                text={translation.admin.positions.list.headline}
                withSeparator={true}
                color={theme.color.white}
                margin="75px 0 0 0"
              />
              <List positions={positions} />
            </>
          )}
        </Container>
      </StyledPositions>
    </Layout>
  );
};

export default Positions;
