import { StyledList } from "./List.Styled";
import Accordion from "../../../UI/Accordion/Accordion";
import { useSelector } from "react-redux";
import Form from "../Form/Form";

const List = ({ caretakerCompanies }) => {
  const theme = useSelector((s) => s.theme);
  return (
    <StyledList>
      <Accordion
        items={caretakerCompanies.map((cC) => ({
          title: cC.name,
          content: <Form caretakerCompany={cC} />,
        }))}
        headerBackgroundColor={theme.color.blue}
        headerColor={theme.color.white}
        bodyBackgroundColor={theme.color.gray}
        bodyColor={theme.color.black}
        margin="40px 0 0 0"
      />
    </StyledList>
  );
};

export default List;
