import fetch, { METHOD } from "../fetch";

export const list = async () => {
  const { status, positions } = await fetch(METHOD.GET, "/position/list");
  if (status && positions) {
    return positions;
  }
  return false;
};

export const upsert = async (token, position) => {
  const { status } = await fetch(METHOD.POST, "/position/upsert", {
    token,
    position,
  });
  return status;
};

export const remove = async (token, uuid) => {
  const { status } = await fetch(METHOD.DELETE, "/position/remove", {
    token,
    uuid,
  });
  return status;
};
