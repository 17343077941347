import { StyledHero } from "./Hero.Styled";

const Hero = ({ img, children, className }) => {
  return (
    <StyledHero img={img} className={className}>
      {children}
    </StyledHero>
  );
};

export default Hero;
