import axios from 'axios'
import fetch, { METHOD } from '../fetch'

export const send = async (payload) => {
  const { status } = await fetch(METHOD.POST, '/contact/send', {
    payload,
  })
  return status
}

const uploadFile = async (file) => {
  const fileMeta = {
    name: file.name,
    type: file.type,
    size: file.size,
  }
  const { status, presignedURL, key } = await fetch(
    METHOD.POST,
    '/contact/manager/get-presigned-url',
    {
      fileMeta,
    },
  )
  if (status && presignedURL && key) {
    const uploadResult = await axios.put(presignedURL, file, {
      headers: { 'Content-Type': file.type },
    })
    return uploadResult.status === 200 ? key : false
  } else {
    return false
  }
}

export const manager = async (payload) => {
  if (Array.isArray(payload.file) && payload.file.length > 0) {
    const file = payload.file[0]
    const key = await uploadFile(file)
    if (key) {
      payload.fileKey = key
    } else {
      return false
    }
  }
  const { status } = await fetch(METHOD.POST, '/contact/manager', {
    payload,
  })
  return status
}
