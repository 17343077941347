import { useSelector } from "react-redux";
import { getUUID } from "../../../utility";
import Container from "../../UI/Container/Container";
import Flex from "../../UI/Flex/Flex";
import Headline from "../../UI/Headline/Headline";
import Text from "../../UI/Text/Text";
import { StyledAboutUs, CustomFlex } from "./AboutUs.Styled";

const AboutUs = () => {
  const translation = useSelector((s) => s.translation);
  const theme = useSelector((s) => s.theme);

  return (
    <StyledAboutUs>
      <Container>
        <Headline
          text={translation.home.aboutUs.headline}
          withSeparator={true}
          color={theme.color.blue}
        />
        <CustomFlex>
          <Flex margin="20px 0 0 0" gap="20px" flex="1 1 0px">
            {translation.home.aboutUs.row1.map((text) => (
              <Text key={getUUID()} text={text} size={theme.size.sm} />
            ))}
          </Flex>
          <Flex margin="20px 0 0 0 " gap="20px" flex="1 1 0px">
            {translation.home.aboutUs.row2.map((text) => (
              <Text key={getUUID()} text={text} size={theme.size.sm} />
            ))}
          </Flex>
        </CustomFlex>
      </Container>
    </StyledAboutUs>
  );
};

export default AboutUs;
