import styled from "styled-components";

export const StyledItem = styled.div`
  text-align: center;
  width: 25%;
  background-color: rgba(255, 255, 255, 0.1);
  padding: 20px 15px;
  border-radius: ${({ theme }) => theme.border.radius};
  img {
    margin-top: 50px;
  }
  div {
    margin: 25px 0px;
  }
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    width: 100%;
    img {
      margin-top: 0px;
    }
    div {
      margin: 15px 0px;
    }
  }
`;
