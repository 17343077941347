import { useEffect, useState } from "react";
import Cookies from "universal-cookie";
import { setUserToken } from "../../../../reducer/action/user";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation, useSearchParams } from "react-router-dom";
import { validateToken } from "../../../../interface/auth";

const Secure = (props) => {
  const authRequired = props.authRequired || false;
  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [searchParams] = useSearchParams();
  const [isEvaluated, setIsEvaluated] = useState(false);

  useEffect(() => {
    const cookies = new Cookies();
    const token = cookies.get("token", { path: "/" });

    const evaluate = async () => {
      let isValid = false;
      if (token) {
        isValid = await validateToken(token);
      }
      if (isValid) {
        dispatch(setUserToken(token));
        setIsEvaluated(true);
      } else if (authRequired) {
        cookies.remove("token", { path: "/" });
        dispatch(setUserToken(null));
        navigate("/");
      } else {
        setIsEvaluated(true);
      }
    };

    if (!isEvaluated) {
      evaluate();
    }
  }, [
    isEvaluated,
    authRequired,
    dispatch,
    navigate,
    user,
    pathname,
    searchParams,
  ]);

  return isEvaluated ? <>{props.children}</> : <></>;
};

export default Secure;
