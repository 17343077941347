import styled from "styled-components";
import Headline from "../../UI/Headline/Headline";

export const StyledGoals = styled.div`
  padding: 100px 0px;
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    padding: 50px 0px;
  }
`;

export const CustomHeadline = styled(Headline)`
  color: ${({ theme }) => theme.color.blue};
  margin-bottom: 100px;
`;

export const Item = styled.div`
  text-align: center;
`;
