import styled from "styled-components";
import Button from "../../UI/Button/Button";

export const StyledSearch = styled.div`
  padding: 100px 0px 50px 0px;
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    padding: 50px 0px;
  }
`;

export const CustomButton = styled(Button)`
  box-shadow: ${({ theme }) => theme.shadow};
  background-color: ${({ theme }) => theme.color.white};
  color: ${({ theme }) => theme.color.blue};
  padding: 15px;
  margin-left: auto;
  margin-right: auto;
  display: block;
`;
