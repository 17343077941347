import { useSelector } from 'react-redux'
import {
  StyledItem,
  CustomImage,
  CustomContent,
  // Manager,
  ManagerList,
  CustomFlex,
  Tel,
  Mail,
  TelEmergency,
  ContactManager,
} from './Item.Styled'
import Flex from '../../UI/Flex/Flex'
import Text from '../../UI/Text/Text'
import sampleImage from '../../../assets/image/estates/platzhalter.jpg'
// import { getUUID } from '../../../utility'
import { useState } from 'react'
import ContactModal from './ContactModal/ContactModal'

const Item = ({ data }) => {
  const translation = useSelector((s) => s.translation)
  const theme = useSelector((s) => s.theme)
  const [showContactModal, setShowContactModal] = useState(false)

  return (
    <StyledItem>
      <CustomFlex>
        <CustomImage src={data.imageURL || sampleImage} />
        <CustomContent>
          {/* <Text
            text={translation.generic.manager}
            color={theme.color.blue}
            size={theme.size.sm}
            font="bold"
          /> */}
          {Array.isArray(data.managerList) && (
            <ManagerList>
              {/* {data.managerList.map((manager) => {
                return (
                  <Manager key={getUUID()}>
                    {manager.phone && (
                      <Flex gap="20px" justify={"left"} margin="5px 0 0 0">
                        <Text
                          text={translation.generic.phone}
                          color={theme.color.lightblue}
                          size={theme.size.sm}
                          width="100px"
                        />
                        <Tel href={`tel:${manager.phone}`}>{manager.phone}</Tel>
                      </Flex>
                    )}
                  </Manager>
                );
              })} */}
              <Flex gap="20px" justify={'left'} margin="5px 0 0 0">
                <ContactManager onClick={() => setShowContactModal(true)}>
                  {translation.estates.item.contactManager.cta}
                </ContactManager>
              </Flex>
            </ManagerList>
          )}

          {data.caretakerCompany && (
            <>
              <Text
                text={translation.generic.caretakerCompany}
                color={theme.color.lightblue}
                size={theme.size.sm}
                margin="30px 0 0 0"
              />
              <Text text={data.caretakerCompany.name} font="bold" size={theme.size.md} />
              <Text
                text={`${data.caretakerCompany.street}, ${data.caretakerCompany.postcode} ${data.caretakerCompany.city}`}
                size={theme.size.sm}
              />

              {data.caretakerCompany.phone && (
                <Flex gap="20px" justify={'left'} margin="30px 0 0 0">
                  <Text
                    text={translation.generic.phone}
                    color={theme.color.lightblue}
                    size={theme.size.sm}
                    width="100px"
                  />
                  <Tel href={`tel:${data.caretakerCompany.phone}`}>
                    {data.caretakerCompany.phone}
                  </Tel>
                </Flex>
              )}

              {data.caretakerCompany.fax && (
                <Flex gap="20px" justify={'left'} margin="5px 0 0 0">
                  <Text
                    text={translation.generic.fax}
                    color={theme.color.lightblue}
                    size={theme.size.sm}
                    width="100px"
                  />
                  <Text width={'auto'} text={data.caretakerCompany.fax} size={theme.size.sm} />
                </Flex>
              )}

              {data.caretakerCompany.mail && (
                <Flex gap="20px" justify={'left'} margin="5px 0 30px 0">
                  <Text
                    text={translation.generic.mail}
                    color={theme.color.lightblue}
                    size={theme.size.sm}
                    width="100px"
                  />
                  <Mail href={`mailto:${data.caretakerCompany.mail}`}>
                    {data.caretakerCompany.mail}
                  </Mail>
                </Flex>
              )}
            </>
          )}
          {data.emergencyNumber &&
            data.caretakerCompany &&
            data.caretakerCompany.name === 'Bock Hausservice' && (
              <TelEmergency
                href={`tel:${data.emergencyNumber}`}
              >{`${translation.generic.emergencyService} - ${data.emergencyNumber}`}</TelEmergency>
            )}
        </CustomContent>
      </CustomFlex>
      {showContactModal && (
        <ContactModal close={() => setShowContactModal(false)} estateUUID={data.uuid} />
      )}
    </StyledItem>
  )
}

export default Item
