import styled from "styled-components";

export const StyledAutocomplete = styled.div`
  margin: ${({ margin }) => margin || "0px"};
  border-radius: ${({ theme }) => theme.border.radius};
  position: relative;
`;

export const Output = styled.div`
  position: absolute;
  width: 100%;
  top: 50px;
  left: 0px;
  background-color: ${({ theme }) => theme.color.white};
  border-radius: ${({ theme }) => theme.border.radius};
  box-sizing: border-box;
  padding: 15px;
  box-shadow: ${({ theme }) => theme.shadow};
  z-index: 3;
`;

export const MultiResultOutput = styled.div`
  margin: 10px 0px;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
`;
export const MultiResultOutputItem = styled.div`
  display: flex;
  width: 150px;
  justify-content: space-between;
  align-items: center;
`;
export const Label = styled.div`
  width: 130px;
  font-size: ${({ theme }) => theme.size.sm};
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;
export const Remove = styled.div`
  width: 20px;
  height: 20px;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  cursor: pointer;
  background-color: ${({ theme }) => theme.color.black};
  color: ${({ theme }) => theme.color.white};
  border-radius: 15px;
`;
