import {
  StyledTeam,
  CustomSection,
  Person,
  Name,
  Tel,
  SplitSection,
  Description,
  CustomHero,
  CustomImage,
  Mail,
} from "./Team.Styled";
import Headline from "../UI/Headline/Headline";
import { useSelector } from "react-redux";
import Layout from "../UI/Layout/Layout";
import Container from "../UI/Container/Container";
import Separator from "../UI/Separator/Separator";
import heroImage from "../../assets/image/team/hero.png";
import logo from "../../assets/image/logo.png";
import Accordion from "../UI/Accordion/Accordion";
import { getUUID } from "../../utility";

const Section = ({ data }) => {
  const translation = useSelector((s) => s.translation);
  return (
    <CustomSection>
      {data.items.map((person) => (
        <Person key={getUUID()}>
          <SplitSection>
            <Name
              text={`${person.salutation} ${person.firstname} ${person.lastname}`}
            />
          </SplitSection>
          <SplitSection>
            {person.phone && (
              <Tel
                href={`tel:${person.phone}`}
              >{`${translation.generic.phone}: ${person.phone}`}</Tel>
            )}
            {person.mobile && (
              <Tel
                href={`tel:${person.mobile}`}
              >{`${translation.generic.mobile}: ${person.mobile}`}</Tel>
            )}
            {person.mail && (
              <Mail
                href={`mailto:${person.mail}`}
              >{`${translation.generic.mail}: ${person.mail}`}</Mail>
            )}
            {person.description && <Description text={person.description} />}
          </SplitSection>
        </Person>
      ))}
    </CustomSection>
  );
};

const Team = () => {
  const translation = useSelector((s) => s.translation);
  const theme = useSelector((s) => s.theme);

  const createItems = () =>
    translation.team.people.map((item) => ({
      title: item.title,
      content: <Section data={item} />,
    }));

  return (
    <Layout>
      <CustomHero img={heroImage}>
        <Container>
          <a href="/">
            <CustomImage src={logo} />
          </a>
          <Separator color={theme.color.white} />
        </Container>
      </CustomHero>
      <Container>
        <StyledTeam>
          <Headline
            text={translation.team.headline}
            color={theme.color.blue}
            withSeparator={true}
          />
          <Accordion
            items={createItems()}
            headerBackgroundColor={theme.color.blue}
            headerColor={theme.color.white}
            bodyBackgroundColor={theme.color.white}
            bodyColor={theme.color.black}
            margin="50px 0 0 0"
          />
        </StyledTeam>
      </Container>
    </Layout>
  );
};

export default Team;
