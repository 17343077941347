import axios from 'axios'
import fetch, { METHOD } from '../fetch'

const uploadFile = async (file) => {
  const fileMeta = {
    name: file.name,
    type: file.type,
    size: file.size,
  }
  const { status, presignedURL, key } = await fetch(METHOD.POST, '/application/get-presigned-url', {
    fileMeta,
  })
  if (status && presignedURL && key) {
    const uploadResult = await axios.put(presignedURL, file, {
      headers: { 'Content-Type': file.type },
    })
    return uploadResult.status === 200 ? key : false
  } else {
    return false
  }
}

export const send = async (payload, files) => {
  if (Array.isArray(files) && files.length > 0) {
    payload.files = []
    const file = files[0]
    const key = await uploadFile(file)
    if (key) {
      payload.files.push({ name: file.name, key })
    } else {
      return false
    }
  }
  const { status } = await fetch(METHOD.POST, '/application/send', payload)
  return status
}
