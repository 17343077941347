import styled from 'styled-components'
import Input from '../../UI/Input/Input'
import Button from '../../UI/Button/Button'

export const StyledSepa = styled.div`
  margin: 50px 0px 100px;
`
export const Headline = styled.div`
  font-size: ${({ theme }) => theme.size.lg};
`

export const InputSplit = styled.div`
  display: flex;
  align-items: center;
  margin-top: 15px;
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    display: block;
  }
`

export const LabelWrapper = styled.div`
  width: 25%;
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    width: 100%;
  }
`
export const Label = styled.div`
  font-size: ${({ theme }) => theme.size.sm};
`

export const InputWrapper = styled.div`
  width: 75%;
  display: flex;
  gap: 15px;
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    width: 100%;
  }
`

export const CustomInput = styled(Input)`
  border: thin solid ${({ theme }) => theme.color.blue};
  width: ${({ width }) => width || '100%'};
`

export const Separator = styled.div`
  margin: 25px 0px;
  border-top: thin solid ${({ theme }) => theme.color.black};
`

export const CheckboxTitle = styled.div`
  font-weight: bold;
  margin-top: 25px;
  font-size: ${({ theme }) => theme.size.sm};
`

export const CheckboxWrapper = styled.div`
  display: flex;
  gap: 15px;
  margin-top: 15px;
`
export const CheckboxInput = styled.input`
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    width: 100px;
  }
`
export const CheckboxLabel = styled.label`
  font-size: ${({ theme }) => theme.size.sm};
  cursor: pointer;
`

export const Hint = styled.div`
  font-size: ${({ theme }) => theme.size.sm};
  margin-top: 25px;
`

export const CTAWrapper = styled.div`
  margin-top: 25px;
  text-align: right;
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    text-align: center;
  }
`

export const CTA = styled(Button)`
  font-size: ${({ theme }) => theme.size.sm};
`
