import styled from 'styled-components'
import Image from '../../Image/Image'
import Link from '../../Link/Link'

export const StyledHeader = styled.div`
  position: absolute;
  display: flex;
  width: 100vw;
  align-items: center;
  padding: 40px 0px;
  z-index: 999;
  .item {
    flex: 1 1 0px;
    &:last-child {
      text-align: right;
    }
  }
`

export const StyledToggle = styled.img`
  height: 40px;
  cursor: pointer;
  position: fixed;
  z-index: 999;
  right: 40px;
  top: 40px;
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    height: 30px;
  }
`

export const Navigation = styled.div`
  padding: 100px 0px 0px 50px;
  background-color: rgba(63, 72, 121, 0.8);
  z-index: 998;
  position: fixed;
  width: 20vw;
  height: 100vh;
  top: 0px;
  right: 0px;
  display: flex;
  gap: 15px;
  flex-wrap: wrap;
  align-content: flex-start;
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    width: 100vw;
    left: 0px;
    padding: 100px 0px 0px 20px;
  }
`

export const CustomLink = styled(Link)`
  width: 100%;
  color: ${({ theme }) => theme.color.white};
`

export const CustomLogo = styled(Image)`
  display: inline-block;
  height: 100px;
  margin-bottom: 20px;
`
