import { useSelector } from 'react-redux'
import { StyledNotAvailable, CTA, Hint } from './NotAvailable.Styled'

const NotAvailable = () => {
  const translation = useSelector((s) => s.translation)
  return (
    <StyledNotAvailable>
      <Hint>{translation.sepa.notAvailable.hint}</Hint>
      <CTA href="/estates">{translation.sepa.notAvailable.cta}</CTA>
    </StyledNotAvailable>
  )
}

export default NotAvailable
