import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import GlobalStyle from './Global.Styled'
import Home from './components/Home/Home'
import Career from './components/Career/Career'
import Estates from './components/Estates/Estates'
import Documents from './components/Documents/Documents'
import { ThemeProvider } from 'styled-components'
import Imprint from './components/Imprint/Imprint'
import DataProtection from './components/DataProtection/DataProtection'
import theme from './shared-resources/theme'
import Contact from './components/Contact/Contact'
import Admin from './components/Admin/Admin'
import Team from './components/Team/Team'
import DamageAndInsurance from './components/DamageAndInsurance/DamageAndInsurance.js'

function App() {
  return (
    <div className="App">
      <ThemeProvider theme={theme}>
        <GlobalStyle />
        <Router>
          <Routes>
            <Route index element={<Home />} />
            <Route path={'career'} element={<Career />} />
            <Route path={'contact'} element={<Contact />} />
            <Route path={'estates'} element={<Estates />} />
            <Route path={'damage-and-insurance'} element={<DamageAndInsurance />} />
            <Route path={'documents'} element={<Documents />} />
            <Route path={'team'} element={<Team />} />
            <Route path={'imprint'} element={<Imprint />} />
            <Route path={'data-protection'} element={<DataProtection />} />
            <Route path={'admin/*'} element={<Admin />} />
            <Route path={'*'} element={<Navigate replace to="/" />} />
          </Routes>
        </Router>
        <ToastContainer />
      </ThemeProvider>
    </div>
  )
}

export default App
