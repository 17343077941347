import styled from 'styled-components'

export const StyledDownloadSection = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 25px;
  margin-top: 30px;
`
export const Download = styled.a`
  background-color: ${({ theme }) => theme.color.blue};
  color: ${({ theme }) => theme.color.white};
  text-decoration: none;
  padding: 10px 20px;
`
export const Hint = styled.div`
  font-size: ${({ theme }) => theme.size.sm};
`
export const Address = styled.div``
