const getStreetNumber = (streetName) => {
  const streetSplit = streetName.split(' ')
  const streetNumberOptions = streetSplit.filter((item) => Number.isInteger(parseInt(item)))
  return streetNumberOptions.length > 0 ? parseInt(streetNumberOptions[0]) : false
}

export const getPlainStreetName = (streetName) => {
  const streetSplit = streetName.split(' ')
  const streetNumber = getStreetNumber(streetName)
  let street = null
  if (streetNumber) {
    const streetNumberIndex = streetSplit.findIndex((item) => parseInt(item) === streetNumber)
    street = streetSplit.slice(0, streetNumberIndex).join(' ')
  } else {
    street = streetName
  }
  return street
}

const sortEstatesByNumbers = (summarizedByStreetObject) => {
  return Object.keys(summarizedByStreetObject).reduce((acc, plainStreetName) => {
    acc[plainStreetName] = summarizedByStreetObject[plainStreetName].sort((a, b) => {
      return getStreetNumber(a.street) - getStreetNumber(b.street)
    })
    return acc
  }, [])
}

// const compareArrays = (arrayOne, arrayTwo) => {
//   if (arrayOne.length === arrayTwo.length) return false
//   return arrayOne.reduce((acc, item, i) => {
//     if (!acc) return false
//     return item === arrayTwo[i]
//   }, true)
// }

// const getManagerUUIDListCheck = (estateOne, estateTwo) => {
//   const managerUUIDListCheck =
//     (Array.isArray(estateOne.managerUUIDList) === Array.isArray(estateTwo.managerUUIDList) &&
//       compareArrays(estateOne.managerUUIDList, estateTwo.managerUUIDList)) ||
//     typeof estateOne.managerUUIDList === typeof estateTwo.managerUUIDList
//   return managerUUIDListCheck
// }

const getManagerUUIDListCheck = (estateOne, estateTwo) => {
  const bothAreArray =
    Array.isArray(estateOne.managerUUIDList) && Array.isArray(estateTwo.managerUUIDList)
  const managerUUIDsAreEqual = estateOne.managerUUIDList.every((managerUUID) => {
    return estateTwo.managerUUIDList.includes(managerUUID)
  })
  return bothAreArray && managerUUIDsAreEqual
}

const summarizeByStreet = (estates) => {
  let streets = {}
  for (const estate of estates) {
    const street = getPlainStreetName(estate.street)

    if (!streets[street]) {
      streets[street] = [estate]
    } else {
      streets[street].push(estate)
    }
  }
  streets = sortEstatesByNumbers(streets)
  return streets
}

const streetsAreRelated = (estateOne, estateTwo) => {
  const streetNumberOne = getStreetNumber(estateOne.street)
  const streetNumberTwo = getStreetNumber(estateTwo.street)
  const numberCheck = streetNumberOne === streetNumberTwo || streetNumberOne === streetNumberTwo - 1
  const caretakerCompanyUUIDCheck =
    estateOne.caretakerCompanyUUID === estateTwo.caretakerCompanyUUID
  const managerUUIDListCheck = getManagerUUIDListCheck(estateOne, estateTwo)

  return numberCheck && managerUUIDListCheck && caretakerCompanyUUIDCheck
}

const summarize = (estates) => {
  const summaryByStreets = summarizeByStreet(estates)
  const estateList = Object.keys(summaryByStreets).reduce((acc, plainStreetName) => {
    const estates = summaryByStreets[plainStreetName]
    for (let i = 0; i < estates.length; i++) {
      const estate = estates[i]
      if (i === 0 || !streetsAreRelated(estates[i - 1], estate)) {
        acc.push([estate])
      } else {
        acc[acc.length - 1].push(estate)
      }
    }
    return acc
  }, [])
  return estateList
}

export default summarize
