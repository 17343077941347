import styled from 'styled-components'

export const StyledNotAvailable = styled.div`
  margin: 25px 0 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

export const Hint = styled.div`
  font-size: ${({ theme }) => theme.size.sm};
`

export const CTA = styled.a`
  font-size: ${({ theme }) => theme.size.sm};
  background-color: ${({ theme }) => theme.color.blue};
  color: ${({ theme }) => theme.color.white};
  padding: 10px 20px;
  text-decoration: none;
  margin-top: 25px;
`
