import styled from "styled-components";
import Autocomplete from "../../../UI/Autocomplete/Autocomplete";
import Flex from "../../../UI/Flex/Flex";
import Form from "../../../UI/Form/Form";
import Input from "../../../UI/Input/Input";

export const StyledForm = styled(Form)``;

export const CustomFlex = styled(Flex)`
  gap: 25px;
`;

export const CustomInput = styled(Input)`
  min-width: 30%;
  flex: 1 1 0px;
`;

export const CustomAutocomplete = styled(Autocomplete)`
  min-width: 30%;
  flex: 1 1 0px;
`;
