import styled from "styled-components";

export const StyledOfficeHours = styled.div`
  padding: 100px 0px;
  background-color: ${({ theme }) => theme.color.lightblue};
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    padding: 50px 0px;
  }
`;

export const Section = styled.div`
  margin-top: 25px;
`;

export const Title = styled.div`
  color: ${({ theme }) => theme.color.blue};
  text-align: center;
  font-family: "bold";
`;

export const Content = styled.div`
  color: ${({ theme }) => theme.color.blue};
  text-align: center;
`;
