import { StyledButton, StyledButtonAnchor } from "./Button.Styled";

const Button = ({
  text,
  onClick,
  href,
  className,
  margin,
  width,
  color,
  display,
  backgroundColor,
  type = "button",
}) => {
  if (href) {
    return (
      <StyledButtonAnchor
        href={href}
        className={className}
        margin={margin}
        width={width}
        color={color}
        display={display}
        backgroundColor={backgroundColor}
      >
        {text}
      </StyledButtonAnchor>
    );
  }
  return (
    <StyledButton
      onClick={onClick}
      className={className}
      margin={margin}
      type={type}
      width={width}
      color={color}
      display={display}
      backgroundColor={backgroundColor}
    >
      {text}
    </StyledButton>
  );
};

export default Button;
