import styled from "styled-components";
import Flex from "../../UI/Flex/Flex";
import heroImage from "../../../assets/image/career/bg-initiative.png";

export const StyledApplyCTASection = styled(Flex)`
  background-image: url(${heroImage});
  background-position: center center;
  background-size: cover;
  text-align: center;
  justify-content: center;
  gap: 75px;
  padding: 100px 20%;
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    padding: 100px 15px;
  }
`;
