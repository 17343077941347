import { StyledInput } from './Input.Styled'

const Input = ({
  onChange,
  value,
  type,
  placeholder,
  margin,
  className,
  color,
  backgroundColor,
  border,
  size,
  required = false,
}) => {
  return (
    <StyledInput
      type={type || 'text'}
      onChange={(e) => onChange(e.target.value)}
      value={value || ''}
      placeholder={placeholder}
      margin={margin}
      required={required}
      className={className}
      color={color}
      backgroundColor={backgroundColor}
      border={border}
      size={size}
    />
  )
}

export default Input
