import { CustomFlex } from "./Form.Styled";
import Input from "../../../UI/Input/Input";
import Flex from "../../../UI/Flex/Flex";
import Button from "../../../UI/Button/Button";
import DragAndDrop from "../../../UI/DragAndDrop/DragAndDrop";
import Form from "../../../UI/Form/Form";
import { useState } from "react";
import { useSelector } from "react-redux";
import toast, { TYPE } from "../../../../toast";
import { send } from "../../../../interface/application";

const ApplyForm = ({ positionUUID }) => {
  const translation = useSelector((s) => s.translation);
  const theme = useSelector((s) => s.theme);
  const initPayload = {
    firstname: "",
    lastname: "",
    mail: "",
    phone: "",
    desiredEntryDate: "",
    salaryExpectation: "",
  };
  const [payload, setPayload] = useState({ ...initPayload });
  const [files, setFiles] = useState(null);

  const apply = async () => {
    const status = await send({ ...payload, positionUUID }, files);
    if (status) {
      toast(TYPE.SUCCESS, translation.career.apply.submit.success);
      setPayload({ ...initPayload });
      setFiles(null);
    } else {
      toast(TYPE.ERROR, translation.career.apply.submit.error);
    }
  };

  const setValue = (prop) => (value) =>
    setPayload({ ...payload, [prop]: value });

  return (
    <Form onSubmit={apply}>
      <CustomFlex>
        <Flex gap="15px">
          <Input
            placeholder={translation.generic.firstname}
            onChange={setValue("firstname")}
            value={payload.firstname}
            required={true}
          />
          <Input
            placeholder={translation.generic.lastname}
            onChange={setValue("lastname")}
            value={payload.lastname}
            required={true}
          />
          <Input
            placeholder={translation.generic.mail}
            onChange={setValue("mail")}
            value={payload.mail}
            type="email"
            required={true}
          />
          <Input
            placeholder={translation.generic.phone}
            onChange={setValue("phone")}
            value={payload.phone}
            required={true}
          />
          <Input
            placeholder={translation.career.apply.desiredEntryDate}
            onChange={setValue("desiredEntryDate")}
            value={payload.desiredEntryDate}
            type="text"
            required={true}
          />
          <Input
            placeholder={translation.career.apply.salaryExpectation}
            onChange={setValue("salaryExpectation")}
            value={payload.salaryExpectation}
            type="number"
            required={true}
          />
        </Flex>
        <DragAndDrop
          background={theme.color.white}
          width="100%"
          title={translation.career.apply.dragAndDrop.title}
          mobileTitle={translation.career.apply.dragAndDrop.mobileTitle}
          text={translation.career.apply.dragAndDrop.text}
          hint={translation.career.apply.dragAndDrop.hint}
          maxFileSizeExceededToast={
            translation.career.apply.dragAndDrop.error.fileSize
          }
          invalidFileExtensionToast={
            translation.career.apply.dragAndDrop.error.fileFormat
          }
          onFile={setFiles}
          value={files}
          maxFileSizeInBytes={10000000}
          allowedFileExtensions={["pdf", "doc", "docx", "jpg", "jpeg", "png"]}
        />
      </CustomFlex>
      <Flex>
        <Button
          type="submit"
          text={translation.career.apply.cta}
          margin="50px 0 0 0 "
        />
      </Flex>
    </Form>
  );
};

export default ApplyForm;
