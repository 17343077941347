import { useSelector } from "react-redux";
import Link from "../../UI/Link/Link";
import { StyledNav } from "./Nav.Styled";

const Nav = () => {
  const translation = useSelector((s) => s.translation);
  return (
    <StyledNav>
      <Link href="/admin/estates" text={translation.admin.nav.estates} />
      <Link href="/admin/managers" text={translation.admin.nav.managers} />
      <Link
        href="/admin/caretaker-company"
        text={translation.generic.caretakerCompany}
      />
      <Link href="/admin/positions" text={translation.admin.nav.positions} />
    </StyledNav>
  );
};

export default Nav;
