import {
  StyledContactModal,
  CTAWrapper,
  SendCTA,
  CustomInput,
  CustomTextarea,
  CustomDragAndDrop,
} from './ContactModal.Styled'
import Modal from '../../../UI/Modal/Modal'
import { useSelector } from 'react-redux'
import { manager as managerContact } from '../../../../interface/contact'
import toast, { TYPE } from '../../../../toast'
import { useState } from 'react'

const ContactModal = ({ close, estateUUID }) => {
  const translation = useSelector((s) => s.translation)
  const theme = useSelector((s) => s.theme)
  const [data, setData] = useState({})

  const sendContactMail = async (e) => {
    e.preventDefault()
    const status = await managerContact({
      ...data,
      estateUUID,
    })
    if (status) {
      toast(TYPE.SUCCESS, translation.estates.item.contactManager.success.send)
      setData({})
      close()
    } else {
      toast(TYPE.ERROR, translation.estates.item.contactManager.error.send)
    }
  }

  const setDataProp = (prop) => (value) => setData({ ...data, [prop]: value })

  return (
    <StyledContactModal>
      <Modal
        title={translation.estates.item.contactManager.headline}
        closeContent={translation.estates.item.contactManager.closeContent}
        open={true}
        close={close}
      >
        <form onSubmit={sendContactMail}>
          <CustomInput
            placeholder={translation.estates.item.contactManager.firstname}
            value={data.firstname}
            onChange={setDataProp('firstname')}
            required
          />
          <CustomInput
            placeholder={translation.estates.item.contactManager.lastname}
            value={data.lastname}
            onChange={setDataProp('lastname')}
            required
          />
          <CustomInput
            placeholder={translation.estates.item.contactManager.mail}
            value={data.mail}
            onChange={setDataProp('mail')}
            required
            type="email"
          />
          <CustomInput
            placeholder={translation.estates.item.contactManager.phone}
            value={data.phone}
            onChange={setDataProp('phone')}
          />
          <CustomInput
            placeholder={translation.estates.item.contactManager.subject}
            value={data.subject}
            onChange={setDataProp('subject')}
            required
          />
          <CustomTextarea
            placeholder={translation.estates.item.contactManager.message}
            value={data.message}
            onChange={(e) => setDataProp('message')(e.target.value)}
            required
          />
          <CustomDragAndDrop
            background={theme.color.white}
            title={translation.estates.item.contactManager.dragAndDrop.title}
            mobileTitle={translation.estates.item.contactManager.dragAndDrop.mobileTitle}
            text={translation.estates.item.contactManager.dragAndDrop.text}
            maxFileSizeExceededToast={
              translation.estates.item.contactManager.dragAndDrop.error.fileSize
            }
            invalidFileExtensionToast={
              translation.estates.item.contactManager.dragAndDrop.error.fileSize
            }
            allowedFileExtensions={['jpg', 'jpeg', 'png', 'pdf']}
            onFile={setDataProp('file')}
            multiple={false}
            value={data.file}
            maxFileSizeInBytes={10000000}
          />
          <CTAWrapper>
            <SendCTA text={translation.estates.item.contactManager.sendCTA} type="submit" />
          </CTAWrapper>
        </form>
      </Modal>
    </StyledContactModal>
  )
}

export default ContactModal
