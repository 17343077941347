import { useSelector } from "react-redux";
import Container from "../../UI/Container/Container";
import Headline from "../../UI/Headline/Headline";
import { StyledOffice } from "./Office.Styled";
import { ImageList, ImageListItem } from "@mui/material";
import img1 from "../../../assets/image/home/office/haghv_office_01.jpg";
import img2 from "../../../assets/image/home/office/haghv_office_02.jpg";
import img3 from "../../../assets/image/home/office/haghv_office_03.jpg";
import img4 from "../../../assets/image/home/office/haghv_office_04.jpg";
import img5 from "../../../assets/image/home/office/haghv_office_05.jpg";
import img6 from "../../../assets/image/home/office/haghv_office_06.jpg";

const Office = () => {
  const translation = useSelector((s) => s.translation);
  const theme = useSelector((s) => s.theme);
  const responsiveState = useSelector((s) => s.responsiveState);

  const itemData = [
    { img: img1, title: "img1" },
    { img: img2, title: "img2" },
    { img: img3, title: "img3" },
    { img: img4, title: "img4" },
    { img: img5, title: "img5" },
    { img: img6, title: "img6" },
  ];

  return (
    <StyledOffice>
      <Container>
        <Headline
          text={translation.home.office.headline}
          withSeparator={true}
          color={theme.color.blue}
          margin="0 0 40px 0"
        />
        <ImageList
          variant="masonry"
          cols={responsiveState?.isMobile ? 1 : 3}
          gap={8}
        >
          {itemData.map((item) => (
            <ImageListItem key={item.img}>
              <img src={`${item.img}`} alt={item.title} loading="lazy" />
            </ImageListItem>
          ))}
        </ImageList>
      </Container>
    </StyledOffice>
  );
};

export default Office;
