import styled from 'styled-components'

export const StyledCard = styled.div`
  background-color: ${({ background, theme }) => background || theme.color.white};
  border: thin solid ${({ theme }) => theme.color.blue};
  padding: 30px 40px;
  display: inline-block;
  border-radius: ${({ theme }) => theme.border.radius};
  position: relative;
  min-width: 20%;
  max-width: ${({ open }) => (open ? '100%' : '23%')};
  width: ${({ open }) => (open ? '100%' : 'auto')};
  flex: ${({ open }) => (open ? 'unset' : '1 1 0px')};
  .border-image {
    position: absolute;
    bottom: -15px;
    position: absolute;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    background-color: ${({ theme }) => theme.color.white};
    padding: 0px 10px;
  }
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    max-width: 100%;
    width: 100%;
    padding: 20px 15px;
    min-width: 50%;
  }
`

export const StyledContent = styled.div`
  margin: 50px 25px;
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    margin: 0px;
  }
`

export const FormWrapper = styled.div`
  padding: 60px;
  background-color: ${({ theme }) => theme.color.lightblue};
  border-radius: ${({ theme }) => theme.border.radius};
  margin-top: 50px;
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    padding: 15px;
  }
`

export const CloseImageWrapper = styled.div`
  position: absolute;
  top: 30px;
  right: 40px;
  cursor: pointer;
`

export const ClickOverlay = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
  cursor: pointer;
`

export const TakeYourChance = styled.div`
  font-size: ${({ theme }) => theme.size.sm};
  font-family: 'bold';
  text-align: left;
  margin-bottom: 50px;
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    margin-top: 25px;
  }
`
