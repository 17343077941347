import styled from "styled-components";
import Flex from "../../../UI/Flex/Flex";

export const CustomFlex = styled(Flex)`
  gap: 50px;
  flex-wrap: nowrap;
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    flex-wrap: wrap;
    gap: 25px;
  }
`;
