import styled from "styled-components";
import Hero from "../../UI/Hero/Hero";

export const StyledLogin = styled.div``;

export const CustomHero = styled(Hero)`
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: 100vh;
`;

export const HeroForm = styled.div`
  color: ${({ theme }) => theme.color.white};
  text-align: center;
  background-color: rgba(63, 72, 121, 0.4);
  padding: 50px 50px;
  border-radius: ${({ theme }) => theme.border.radius};
  margin-top: 50px;
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    padding: 50px 15px;
  }
`;
