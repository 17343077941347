import styled from 'styled-components'

export const StyledOpenPositions = styled.div`
  padding: 100px 0px;
  text-align: center;
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    padding: 50px 0px;
  }
`

export const IHKImage = styled.div`
  border-radius: ${({ theme }) => theme.border.radius};
  background-image: url(${({ src }) => src});
  width: 250px;
  height: 145px;
  background-size: 254px 147px;
  background-position: center center;
  border: thin solid rgb(123, 122, 127);
  margin-bottom: 25px;
  margin-left: auto;
  margin-right: auto;
`

export const Intro = styled.div`
  font-size: ${({ theme }) => theme.size.sm};
  text-align: center;
  margin-bottom: 25px;
`
