import styled from "styled-components";
import Hero from "../UI/Hero/Hero";

export const CustomHero = styled(Hero)`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Logo = styled.img`
  height: 250px;
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    height: 150px;
  }
`;

export const HeroText = styled.div`
  color: ${({ theme }) => theme.color.white};
  position: absolute;
  bottom: 50px;
  text-align: center;
`;

export const Intro = styled.div`
  background-color: ${({ theme }) => theme.color.lightblue};
  color: ${({ theme }) => theme.color.blue};
  text-align: center;
  padding: 100px 0px;
  font-weight: 900;
`;

export const VideoSection = styled.div`
  background-color: ${({ theme }) => theme.color.gray};
  padding: 100px 0px;
  text-align: center;
`;
