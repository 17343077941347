import iconStandard from "../../../assets/image/career/Icon_Standard.png";
import iconTeam from "../../../assets/image/career/Icon_Team.png";
import iconService from "../../../assets/image/career/Icon_Service.png";
import Flex from "../../UI/Flex/Flex";
import Text from "../../UI/Text/Text";
import Container from "../../UI/Container/Container";
import { StyledGoals, CustomHeadline as Headline } from "./Goals.Styled";
import { useSelector } from "react-redux";
import Item from "./Item/Item";

const Goals = () => {
  const translation = useSelector((s) => s.translation);
  const theme = useSelector((s) => s.theme);

  return (
    <StyledGoals>
      <Container>
        <Headline
          text={translation.career.goals.headline}
          withSeparator={true}
          color={theme.color.blue}
        />
        <Flex gap="50px">
          <Item
            img={iconStandard}
            title={translation.career.goals.item1.title}
            text={translation.career.goals.item1.text}
          />
          <Item
            img={iconTeam}
            title={translation.career.goals.item2.title}
            text={translation.career.goals.item2.text}
          />
          <Item
            img={iconService}
            title={translation.career.goals.item3.title1}
            title2={translation.career.goals.item3.title2}
            text={translation.career.goals.item3.text}
          />
        </Flex>
        <Text
          text={translation.career.goals.text}
          align="center"
          margin="100px 0px 0px 0px"
          color={theme.color.blue}
        />
      </Container>
    </StyledGoals>
  );
};

export default Goals;
