import styled, { keyframes } from "styled-components";
import { pulse } from "react-animations";

const incomingAnimation = keyframes`${pulse}`;

export const StyledModal = styled.div`
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  z-index: 9997;
`;

export const Background = styled.div`
  z-index: 9998;
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0px;
  left: 0px;
  padding-top: 15px;
  padding-bottom: 15px;
  background: rgba(0, 0, 0, 0.3);
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
`;

export const Core = styled.div`
  position: absolute;
  z-index: 9999;
  background-color: ${({ theme }) => theme.color.white};
  box-shadow: ${({ theme }) => theme.shadow};
  margin-left: auto;
  margin-right: auto;
  animation: 0.25s ${incomingAnimation};
  border-radius: ${({ theme }) => theme.border.radius};
  overflow: hidden;
  @media (min-width: ${({ theme }) => theme.breakpoint.sm}) {
    width: 750px;
  }
  @media (min-width: ${({ theme }) => theme.breakpoint.md}) {
    width: calc(100vw - 60px);
    margin: 0px;
    width: 970px;
  }
  @media (min-width: ${({ theme }) => theme.breakpoint.lg}) {
    width: 1170px;
  }
`;
export const Header = styled.div`
  display: flex;
  background-color: ${({ theme }) => theme.color.white};
  align-items: center;
  padding: 15px;
  box-sizing: border-box;
  width: inherit;
  border-bottom: thin solid ${({ theme }) => theme.color.blue};
`;
export const Title = styled.div`
  flex: 1 1 0px;
`;
export const Close = styled.div`
  cursor: pointer;
`;
export const Content = styled.div`
  padding: 15px;
  overflow-x: hidden;
  overflow-y: scroll;
  max-height: 70vh;
  box-sizing: border-box;
`;

export const Footer = styled.div`
  display: flex;
  background-color: ${({ theme }) => theme.color.white};
  width: inherit;
  border-top: thin solid ${({ theme }) => theme.color.blue};
  padding: 15px;
`;
