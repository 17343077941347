import styled from 'styled-components'
import Flex from '../../UI/Flex/Flex'

export const StyledItem = styled.div``

export const CustomImage = styled.div`
  background-image: url(${({ src }) => src});
  border-radius: ${({ theme }) => theme.border.radius};
  width: 30%;
  background-position: center center;
  background-size: cover;
  max-height: 300px;
  min-height: 250px;
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    width: 100%;
  }
`

export const CustomContent = styled.div`
  flex: 1 1 0px;
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    flex: auto;
    width: 100%;
  }
`
export const Manager = styled.div`
  margin-bottom: 10px;
`
export const ManagerList = styled.div``

export const CustomFlex = styled(Flex)`
  gap: 30px;
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    flex-wrap: wrap;
  }
`

export const Tel = styled.a`
  color: ${({ theme }) => theme.color.black};
  font-size: ${({ theme }) => theme.size.sm};
  cursor: pointer;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
`

export const Mail = styled.a`
  color: ${({ theme }) => theme.color.black};
  font-size: ${({ theme }) => theme.size.sm};
  cursor: pointer;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
`

export const ContactManager = styled.button`
  color: ${({ theme }) => theme.color.white};
  background-color: ${({ theme }) => theme.color.blue};
  border-radius: ${({ theme }) => theme.border.radius};
  font-size: ${({ theme }) => theme.size.sm};
  padding: 5px 10px;
  border: none;
  cursor: pointer;
`

export const TelEmergency = styled.a`
  color: ${({ theme }) => theme.color.blue};
  font-size: ${({ theme }) => theme.size.sm};
  font-family: 'bold';
  cursor: pointer;
  text-decoration: none;
  text-align: right;
  display: block;
  margin-top: 20px;
  &:hover {
    text-decoration: underline;
  }
`
