import Image from "../../Image/Image";
import { StyledItem, Header, Body } from "./Item.Styled";
import plusIcon from "../../../../assets/image/plus.png";
import { useSelector } from "react-redux";
import Text from "../../Text/Text";

const Item = ({
  title,
  content,
  headerBackgroundColor,
  headerColor,
  bodyBackgroundColor,
  bodyColor,
  open,
  width,
  toggle,
  borderRadius,
  id,
}) => {
  const theme = useSelector((s) => s.theme);

  return (
    <StyledItem width={width} id={id}>
      <Header
        onClick={toggle}
        background={headerBackgroundColor}
        borderRadius={borderRadius}
      >
        <Text
          text={title}
          size={theme.size.sm}
          color={headerColor}
          font="bold"
        />
        <Image className="plus-icon" src={plusIcon} height="20px" />
      </Header>
      {open && (
        <Body
          background={bodyBackgroundColor}
          color={bodyColor}
          borderRadius={borderRadius}
        >
          {content}
        </Body>
      )}
    </StyledItem>
  );
};

export default Item;
