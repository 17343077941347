import { StyledContact, CustomHero, HeroForm } from "./Contact.Styled";
import heroImage from "../../assets/image/contact/hero.jpg";
import logo from "../../assets/image/contact/logo.png";
import { useSelector } from "react-redux";
import Form from "../UI/Form/Form";
import Container from "../UI/Container/Container";
import Layout from "../UI/Layout/Layout";
import Headline from "../UI/Headline/Headline";
import Input from "../UI/Input/Input";
import Textarea from "../UI/Textarea/Textarea";
import Image from "../UI/Image/Image";
import Flex from "../UI/Flex/Flex";
import Button from "../UI/Button/Button";
import { useState } from "react";
import { send } from "../../interface/contact";
import toast, { TYPE } from "../../toast";

const Contact = () => {
  const translation = useSelector((s) => s.translation);
  const theme = useSelector((s) => s.theme);
  const init = {
    firstname: "",
    lastname: "",
    mail: "",
    message: "",
  };
  const [payload, setPayload] = useState(init);

  const submit = async () => {
    const status = await send(payload);
    if (status) {
      toast(TYPE.SUCCESS, translation.contact.submit.success);
      setPayload(init);
    } else {
      toast(TYPE.ERROR, translation.contact.submit.error);
    }
  };

  const update = (prop) => (value) => setPayload({ ...payload, [prop]: value });

  return (
    <Layout>
      <StyledContact>
        <CustomHero img={heroImage}>
          <Container>
            <Headline
              text={translation.contact.headline}
              color={theme.color.white}
              withSeparator={true}
            />
            <HeroForm>
              <Form onSubmit={submit}>
                <Flex gap="15px">
                  <Image src={logo} height="50px" margin="0 0 30px 0" />
                  <Input
                    placeholder={translation.generic.firstname}
                    onChange={update("firstname")}
                    value={payload.firstname}
                    required={true}
                  />
                  <Input
                    placeholder={translation.generic.lastname}
                    onChange={update("lastname")}
                    value={payload.lastname}
                    required={true}
                  />
                  <Input
                    placeholder={translation.generic.mail}
                    onChange={update("mail")}
                    value={payload.mail}
                    type="email"
                    required={true}
                  />
                  <Textarea
                    placeholder={translation.contact.message.placeholder}
                    onChange={update("message")}
                    value={payload.message}
                    rows={5}
                    required={true}
                  />
                  <Button type="submit" text={translation.contact.cta} />
                </Flex>
              </Form>
            </HeroForm>
          </Container>
        </CustomHero>
      </StyledContact>
    </Layout>
  );
};

export default Contact;
