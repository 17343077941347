import styled from "styled-components";

export const StyledItem = styled.div`
  text-align: center;
  flex: 1 1 0;
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    width: 100%;
    flex: none;
  }
`;
