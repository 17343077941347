import { useSelector } from 'react-redux'
import Container from '../../UI/Container/Container'
import Headline from '../../UI/Headline/Headline'
import { StyledApply } from './Apply.Styled'
import Form from './Form/Form'

const Apply = () => {
  const translation = useSelector((s) => s.translation)
  const theme = useSelector((s) => s.theme)

  return (
    <StyledApply id="apply">
      <Container>
        <Headline
          text={translation.career.apply.headline}
          color={theme.color.white}
          withSeparator={true}
          margin="0 0 50px 0"
        />
        <Form />
      </Container>
    </StyledApply>
  )
}

export default Apply
