import { StyledLogin, CustomHero, HeroForm } from "./Login.Styled";
import Form from "../../UI/Form/Form";
import Layout from "../../UI/Layout/Layout";
import Container from "../../UI/Container/Container";
import Headline from "../../UI/Headline/Headline";
import Input from "../../UI/Input/Input";
import Flex from "../../UI/Flex/Flex";
import { useSelector, useDispatch } from "react-redux";
import heroImage from "../../../assets/image/admin/hero.jpg";
import logo from "../../../assets/image/logo.png";
import Image from "../../UI/Image/Image";
import { useState } from "react";
import Button from "../../UI/Button/Button";
import { login } from "../../../interface/auth";
import toast, { TYPE } from "../../../toast";
import Cookies from "universal-cookie";
import { useNavigate } from "react-router-dom";
import { setUserToken } from "../../../reducer/action/user";

const Login = () => {
  const translation = useSelector((s) => s.translation);
  const theme = useSelector((s) => s.theme);
  const [password, setPassword] = useState("");
  const dispatch = useDispatch();
  const cookies = new Cookies();
  const navigate = useNavigate();

  const submit = async () => {
    const token = await login(password);
    if (token) {
      dispatch(setUserToken(token));
      cookies.set("token", token, { path: "/" });
      navigate("/admin/estates");
    } else {
      toast(TYPE.ERROR, translation.admin.login.error);
    }
  };

  return (
    <Layout>
      <StyledLogin>
        <CustomHero img={heroImage}>
          <Container>
            <Headline
              text={translation.admin.login.headline}
              color={theme.color.white}
              withSeparator={true}
            />
            <HeroForm>
              <Form onSubmit={submit}>
                <Flex gap="15px">
                  <Image src={logo} height="100px" margin="0 0 30px 0" />
                  <Input
                    placeholder={translation.generic.password}
                    onChange={setPassword}
                    value={password}
                    required={true}
                    type="password"
                  />
                  <Button type="submit" text={translation.admin.login.cta} />
                </Flex>
              </Form>
            </HeroForm>
          </Container>
        </CustomHero>
      </StyledLogin>
    </Layout>
  );
};

export default Login;
