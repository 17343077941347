import fetch, { METHOD } from '../fetch'

export const list = async (token) => {
  const { status, managers } = await fetch(METHOD.GET, '/manager/list', {
    token,
  })
  if (status && managers) {
    return managers
  }
  return false
}

export const upsert = async (token, manager) => {
  const { status } = await fetch(METHOD.POST, '/manager/upsert', {
    token,
    manager,
  })
  return status
}

export const search = async (token, input) => {
  const { status, managers } = await fetch(METHOD.GET, '/manager/search', {
    token,
    input,
  })
  return status && Array.isArray(managers) ? managers : false
}

export const remove = async (token, uuid) => {
  const { status } = await fetch(METHOD.DELETE, '/manager/remove', {
    token,
    uuid,
  })
  return status
}
