import styled from "styled-components";
import Flex from "../../UI/Flex/Flex";

export const StyledAboutUs = styled.div`
  background-color: ${({ theme }) => theme.color.gray};
  padding: 100px 0px;
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    padding: 50px 0px;
  }
`;

export const CustomFlex = styled(Flex)`
  gap: 50px;
  flex-wrap: nowrap;
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    flex-wrap: wrap;
  }
`;
