import Layout from "../../UI/Layout/Layout";
import { StyledCaretakerCompany } from "./CaretakerCompany.Styled";
import { list } from "../../../interface/caretakerCompany";
import { useState } from "react";
import { useEffect } from "react";
import Headline from "../../UI/Headline/Headline";
import { useSelector } from "react-redux";
import Container from "../../UI/Container/Container";
import Form from "./Form/Form";
import List from "./List/List";
import Nav from "../Nav/Nav";

const CaretakerCompany = () => {
  const translation = useSelector((s) => s.translation);
  const theme = useSelector((s) => s.theme);
  const [caretakerCompanies, setCaretakerCompanies] = useState(null);

  useEffect(() => {
    const fetch = async () => {
      const caretakerCompanies = await list();
      setCaretakerCompanies(caretakerCompanies);
    };

    if (!caretakerCompanies) {
      fetch();
    }
  }, [caretakerCompanies]);

  return (
    <Layout authRequired={true}>
      <Nav />
      <StyledCaretakerCompany>
        <Container>
          <Headline
            text={translation.admin.caretakerCompany.create.headline}
            withSeparator={true}
            color={theme.color.white}
          />
          <Form />
          {caretakerCompanies && (
            <>
              <Headline
                text={translation.admin.caretakerCompany.list.headline}
                withSeparator={true}
                color={theme.color.white}
                margin="75px 0 0 0"
              />
              <List caretakerCompanies={caretakerCompanies} />
            </>
          )}
        </Container>
      </StyledCaretakerCompany>
    </Layout>
  );
};

export default CaretakerCompany;
