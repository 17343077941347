import styled from "styled-components";

export const StyledDragAndDrop = styled.div`
  cursor: pointer;
  width: ${({ width }) => width || "auto"};
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  background: ${({ background }) => background || "transparent"};
  border-radius: ${({ theme }) => theme.border.radius};
  padding: 40px;
  &:focus,
  &:active {
    outline: none;
  }
`;
