import { StyledPoint, Number, CustomText } from "./Point.Styled";
import { addMissingZero } from "../../../../utility";
import { useSelector } from "react-redux";

const Point = ({ number, text }) => {
  const theme = useSelector((s) => s.theme);

  return (
    <StyledPoint>
      <div className="content">
        <Number>{addMissingZero(number + 1)}</Number>
        <CustomText
          text={text}
          size={theme.size.sm}
          color={theme.color.white}
        />
      </div>
    </StyledPoint>
  );
};

export default Point;
