import styled from "styled-components";
import Hero from "../UI/Hero/Hero";
import Image from "../UI/Image/Image";
import Text from "../UI/Text/Text";

export const StyledEstates = styled.div`
  padding: 100px 0px;
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    padding: 50px 0px;
  }
`;

export const CustomHero = styled(Hero)`
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
`;

export const CustomImage = styled(Image)`
  height: 250px;
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    height: 150px;
  }
`;

export const ShowAllToggle = styled(Text)`
  text-align: center;
  color: ${({ theme }) => theme.color.blue};
  font-size: ${({ theme }) => theme.size.sm};
  margin-top: 20px;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
`;
