import {
  CustomHero,
  Logo,
  HeroText,
  Intro,
  VideoSection,
} from "./Career.Styled";
import Layout from "../UI/Layout/Layout";
import Separator from "../UI/Separator/Separator";
import heroImage from "../../assets/image/career/hero.png";
import logo from "../../assets/image/logo.png";
import { useSelector } from "react-redux";
import Container from "../UI/Container/Container";
import Button from "../UI/Button/Button";
import ApplyCTASection from "./ApplyCTASection/ApplyCTASection";
import Apply from "./Apply/Apply";
// import FAQ from "./FAQ/FAQ";
// import OurTeam from "./OurTeam/OurTeam";
import Timeline from "./Timeline/Timeline";
import OpenPositions from "./OpenPositions/OpenPositions";

const Career = () => {
  const translation = useSelector((s) => s.translation);
  const theme = useSelector((s) => s.theme);

  return (
    <Layout>
      <CustomHero img={heroImage}>
        <a href="/">
          <Logo src={logo} />
        </a>
        <HeroText>
          <Separator color={theme.color.white} />
          {translation.career.hero.text1}
          <br />
          {translation.career.hero.text2}
        </HeroText>
      </CustomHero>
      <Intro>
        <Container>
          {translation.career.intro}
          <Separator color={theme.color.blue} />
        </Container>
      </Intro>
      <VideoSection>
        <Button
          text={translation.career.videoSection.button}
          href={"#open-positions"}
        />
      </VideoSection>
      <ApplyCTASection />
      <Timeline />
      <OpenPositions />
      <Apply />
      {/* <FAQ /> */}
      {/* <OurTeam /> */}
    </Layout>
  );
};

export default Career;
