import styled from "styled-components";
import Text from "../../../UI/Text/Text";

export const StyledPoint = styled.div`
  width: calc(25% - 30px);
  .content {
    display: flex;
    align-items: center;
    gap: 15px;
    justify-content: center;
    margin-bottom: 25px;
    flex-wrap: wrap;
  }
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    width: 100%;
    .content {
      margin-bottom: 0px;
      flex-wrap: no-wrap;
      width: 100%;
      justify-content: left;
    }
  }
`;

export const Number = styled.div`
  width: 60px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  background-color: ${({ theme }) => theme.color.white};
  color: ${({ theme }) => theme.color.blue};
  border-radius: 50px;
  font-family: "bold";
  font-size: ${({ theme }) => theme.size.md};
`;

export const CustomText = styled(Text)`
  width: 100%;
  text-align: center;
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    width: auto;
    width: calc(100% - 80px);
    text-align: left;
  }
`;
