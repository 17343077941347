import { StyledApplyCTASection } from "./ApplyCTASection.Styled";
import Text from "../../UI/Text/Text";
import Button from "../../UI/Button/Button";
import { useSelector } from "react-redux";

const ApplyCTASection = () => {
  const translation = useSelector((s) => s.translation);
  const theme = useSelector((s) => s.theme);

  return (
    <StyledApplyCTASection>
      <Text
        text={translation.career.applyCTASection.title}
        size={"50px"}
        color={theme.color.white}
        align={"center"}
        font={"bold"}
      />
      <Button text={translation.career.applyCTASection.cta} href="#apply" />
    </StyledApplyCTASection>
  );
};

export default ApplyCTASection;
