import { useSelector } from 'react-redux'
import Container from '../../UI/Container/Container'
import Headline from '../../UI/Headline/Headline'
import Text from '../../UI/Text/Text'
import { StyledOpenPositions, IHKImage, Intro } from './OpenPositions.Styled'
import Card from './Card/Card'
import Flex from '../../UI/Flex/Flex'
import { getUUID } from '../../../utility'
import { useState } from 'react'
import { useEffect } from 'react'
import { list } from '../../../interface/position'
import ihk from '../../../assets/image/career/ihk.jpg'

const OpenPositions = () => {
  const translation = useSelector((s) => s.translation)
  const theme = useSelector((s) => s.theme)
  const [openPositions, setOpenPositions] = useState(null)
  const [selectedPosition, setSelectedPosition] = useState(null)

  useEffect(() => {
    const fetchPositions = async () => {
      const positions = await list()
      setOpenPositions(positions)
    }
    if (!openPositions) {
      fetchPositions()
    }
  }, [openPositions])

  if (!openPositions) return <></>
  return (
    <StyledOpenPositions id="open-positions">
      <Container>
        <Headline
          text={translation.career.openPositions.headline}
          color={theme.color.blue}
          withSeparator={true}
        />
        <IHKImage src={ihk} alt="IHK-Zertifikat" />
        <Text
          align="center"
          text={translation.career.openPositions.text}
          margin="25px 0px"
          size={theme.size.sm}
        />
        <Intro>{translation.career.openPositions.intro}</Intro>
        <Flex gap="30px">
          {openPositions.map(({ uuid, title, takeYourChance, tasks, important, ourOfferings }) => (
            <Card
              uuid={uuid}
              title={title}
              takeYourChance={takeYourChance}
              tasks={tasks}
              important={important}
              ourOfferings={ourOfferings}
              selected={selectedPosition === uuid}
              setSelected={() => setSelectedPosition(selectedPosition === uuid ? null : uuid)}
              unselect={() => setSelectedPosition(null)}
              key={getUUID()}
            />
          ))}
          <Card
            title={translation.career.openPositions.initiativeApplication}
            background={theme.color.blue}
            color={theme.color.white}
            borderImage={false}
            href="#apply"
          />
        </Flex>
      </Container>
    </StyledOpenPositions>
  )
}

export default OpenPositions
