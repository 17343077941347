import fetch, { METHOD } from "../fetch";

export const list = async (token) => {
  const { status, caretakerCompanies } = await fetch(
    METHOD.GET,
    "/caretakerCompany/list",
    {
      token,
    }
  );
  if (status && caretakerCompanies) {
    return caretakerCompanies;
  }
  return false;
};

export const upsert = async (token, caretakerCompany) => {
  const { status } = await fetch(METHOD.POST, "/caretakerCompany/upsert", {
    token,
    caretakerCompany,
  });
  return status;
};

export const search = async (token, input) => {
  const { status, caretakerCompanies } = await fetch(
    METHOD.GET,
    "/caretakerCompany/search",
    {
      token,
      input,
    }
  );
  return status && Array.isArray(caretakerCompanies)
    ? caretakerCompanies
    : false;
};

export const remove = async (token, uuid) => {
  const { status } = await fetch(METHOD.DELETE, "/caretakerCompany/remove", {
    token,
    uuid,
  });
  return status;
};
