import Separator from '../Separator/Separator'
import { StyledHeadline } from './Headline.Styled'

const Headline = ({ text, withSeparator, color, className, margin, size, sizeMobile }) => {
  return (
    <StyledHeadline
      color={color}
      className={className}
      margin={margin}
      size={size}
      sizeMobile={sizeMobile}
    >
      {text}
      {withSeparator && <Separator color={color} />}
    </StyledHeadline>
  )
}

export default Headline
