import styled from "styled-components";

export const StyledTimeline = styled.div`
  background-color: ${({ theme }) => theme.color.blue};
  padding: 100px 0px;
  text-align: center;
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    padding: 50px 0px;
  }
`;
