import { StyledEstates, CustomHero, CustomImage, ShowAllToggle } from './Estates.Styled'
import Layout from '../UI/Layout/Layout'
import Text from '../UI/Text/Text'
import Headline from '../UI/Headline/Headline'
import { useSelector } from 'react-redux'
import Container from '../UI/Container/Container'
import Separator from '../UI/Separator/Separator'
import heroImage from '../../assets/image/estates/hero.jpg'
import logo from '../../assets/image/logo.png'
import Accordion from '../UI/Accordion/Accordion'
import Item from './Item/Item'
import { useEffect } from 'react'
import { useState } from 'react'
import { list } from '../../interface/estate'
import { useSearchParams } from 'react-router-dom'
import Input from '../UI/Input/Input'
import summarize from './helper/summarize'
import labelGenerator from './helper/labelGenerator'

const Estates = () => {
  const translation = useSelector((s) => s.translation)
  const theme = useSelector((s) => s.theme)
  const token = useSelector((s) => s.userToken)
  const [estates, setEstates] = useState(null)
  const [searchInput, setSearchInput] = useState('')
  // eslint-disable-next-line no-unused-vars
  const [searchParams, setSearchParams] = useSearchParams()
  const openItemUUID = searchParams.get('open')
  const [showAll, setShowAll] = useState(false)
  // eslint-disable-next-line no-unused-vars
  const [showAllButtonVisible, setShowAllButtonVisible] = useState(false)

  useEffect(() => {
    const result = searchInput.trim().length === 0 && !openItemUUID && !showAll
    if (result !== showAllButtonVisible) {
      setShowAllButtonVisible(result)
    }
  }, [searchInput, openItemUUID, showAllButtonVisible, showAll])

  useEffect(() => {
    const fetchEstates = async () => {
      const estates = await list(token)
      setEstates(estates)
    }

    if (!estates && searchParams) {
      fetchEstates()
    }
  }, [estates, token, searchParams])

  const filterBySearch = (estate) => {
    if (searchInput.trim().length > 0) {
      return (
        estate.street.toLowerCase().includes(searchInput.toLowerCase()) ||
        estate.postcode.toLowerCase().includes(searchInput.toLowerCase())
      )
    } else if (openItemUUID) {
      return estate.uuid === openItemUUID
    }
    return true
  }

  const getStreetsSlice = (estate, i) => {
    if (searchInput.trim().length > 0 || !showAllButtonVisible) return true
    return i < 10 || !showAllButtonVisible
  }

  const createItems = () => {
    const filteredEstates = estates.filter(filterBySearch)
    const summarizedEstates = summarize(filteredEstates)
    const streets = Object.keys(summarizedEstates)
    return streets.filter(getStreetsSlice).map((streetName) => {
      const summarizedStreetArray = summarizedEstates[streetName]
      const item = summarizedStreetArray[0]
      return {
        title: labelGenerator(summarizedStreetArray),
        content: <Item data={item} />,
      }
    })
  }

  const getOpenIndex = () => (openItemUUID && searchInput === '' ? 0 : -1)

  return (
    <Layout>
      <CustomHero img={heroImage}>
        <Container>
          <a href="/">
            <CustomImage src={logo} />
          </a>
          <Separator color={theme.color.white} />
        </Container>
      </CustomHero>
      <StyledEstates>
        <Container>
          <Headline
            text={translation.estates.headline}
            color={theme.color.blue}
            withSeparator={true}
          />
          <Text text={translation.estates.text} align="center" size={theme.size.sm} />
          <Input
            margin="10px 0 0 0"
            backgroundColor={theme.color.blue}
            placeholder={translation.estates.search.placeholder}
            color={theme.color.white}
            size={theme.size.md}
            value={searchInput}
            onChange={setSearchInput}
          />
          {estates && (
            <Accordion
              initOpenIndex={getOpenIndex()}
              items={createItems()}
              headerBackgroundColor={theme.color.blue}
              headerColor={theme.color.white}
              bodyBackgroundColor={theme.color.gray}
              bodyColor={theme.color.black}
              margin="50px 0 0 0"
            />
          )}
          {showAllButtonVisible && (
            <ShowAllToggle
              onClick={() => setShowAll(true)}
              text={translation.estates.showAllToggle}
            />
          )}
        </Container>
      </StyledEstates>
    </Layout>
  )
}

export default Estates
