import { useDropzone } from 'react-dropzone'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import { getUUID } from '../../../utility'
import Text from '../Text/Text'
import { StyledDragAndDrop } from './DragAndDrop.Styled'
import toast, { TYPE } from '../../../toast'

const ItalicText = styled(Text)`
  font-style: italic;
`

const FileList = styled.div`
  display: flex;
  gap: 5px;
  margin-top: 20px;
  flex-wrap: wrap;
`

const DragAndDrop = ({
  title,
  mobileTitle,
  text,
  hint,
  onFile,
  value,
  width,
  background,
  maxFileSizeInBytes,
  allowedFileExtensions,
  maxFileSizeExceededToast,
  invalidFileExtensionToast,
  className,
  multiple = true,
}) => {
  const theme = useSelector((s) => s.theme)
  const responsiveState = useSelector((s) => s.responsiveState)

  const getExtension = (filename) => filename.split('.').pop()

  const onDrop = (files) => {
    const isValid = files.reduce((acc, file) => {
      if (!acc) return false
      if (file.size > maxFileSizeInBytes) {
        toast(TYPE.ERROR, maxFileSizeExceededToast)
        return false
      }

      if (!allowedFileExtensions.includes(getExtension(file.name).toLowerCase())) {
        toast(TYPE.ERROR, invalidFileExtensionToast)
        return false
      }
      return true
    }, true)
    onFile(isValid ? files : [])
  }

  const { getRootProps, getInputProps } = useDropzone({ onDrop, multiple })
  // isDragActive is also a property

  return (
    <StyledDragAndDrop
      {...getRootProps()}
      width={width}
      background={background}
      className={className}
    >
      <input {...getInputProps()} />
      <div>
        <Text
          text={responsiveState && responsiveState.isMobile && mobileTitle ? mobileTitle : title}
          color={theme.color.blue}
          size={theme.size.md}
          align="center"
          font="bold"
        />
        <Text
          text={text}
          color={theme.color.black}
          size={theme.size.sm}
          margin="20px 0 0 0 "
          align="center"
        />
        <ItalicText text={hint} color={theme.color.black} size={theme.size.sm} align="center" />
        {Array.isArray(value) && value.length > 0 && (
          <FileList>
            {value.map((file) => (
              <Text text={file.name} size={theme.size.sm} key={getUUID()} align="center" />
            ))}
          </FileList>
        )}
      </div>
    </StyledDragAndDrop>
  )
}

export default DragAndDrop
