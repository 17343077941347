import styled from "styled-components";

export const StyledFooter = styled.div`
  display: flex;
  width: 100%;
  padding: 50px 0px;
  align-items: center;
`;

export const Column = styled.div`
  flex: 1 1 0px;
`;
