import { useState } from 'react'
import { useSelector } from 'react-redux'
import {
  StyledAutocomplete,
  Output,
  MultiResultOutput,
  MultiResultOutputItem,
  Label,
  Remove,
} from './Autocomplete.Styled'
import toast, { TYPE } from '../../../toast'
import Text from '../Text/Text'
import { getUUID } from '../../../utility'
import Item from './Item/Item'
import Input from '../Input/Input'

const Autocomplete = ({
  placeholder,
  margin,
  className,
  type,
  searchInterface,
  renderFunction,
  onChange,
  value,
  backgroundColor,
  border,
  size,
  color,
  multiple = false,
}) => {
  const translation = useSelector((s) => s.translation)
  const theme = useSelector((s) => s.theme)
  const token = useSelector((s) => s.userToken)
  const [input, setInput] = useState(!multiple && value ? renderFunction(value) : '')
  const [results, setResults] = useState(null)

  const search = async (input) => {
    setInput(input)
    if (input.trim().length > 0) {
      const results = await searchInterface(token, input)
      if (Array.isArray(results)) {
        setResults(results)
      } else if (results !== 'no-show') {
        toast(TYPE.ERROR, translation.UI.autocomplete.searchError)
      }
    } else {
      setResults(null)
    }
  }

  const clicked = (data) => () => {
    if (!multiple) {
      setInput(renderFunction(data))
      onChange(data)
    } else {
      onChange([...value, data])
    }
    setResults(null)
  }

  const remove = (index) => () => onChange(value.filter((item, i) => i !== index))

  return (
    <StyledAutocomplete
      className={className}
      margin={margin}
      backgroundColor={backgroundColor}
      color={color}
    >
      <Input
        type={type || 'text'}
        onChange={search}
        value={input}
        placeholder={placeholder}
        color={color}
        backgroundColor={backgroundColor}
        size={size}
        border={border}
      />
      {multiple && Array.isArray(value) && value.length > 0 && (
        <MultiResultOutput>
          {value.map((item, i) => (
            <MultiResultOutputItem key={getUUID()}>
              <Label>{renderFunction(item)}</Label>
              <Remove onClick={remove(i)}>X</Remove>
            </MultiResultOutputItem>
          ))}
        </MultiResultOutput>
      )}
      {results && results.length > 0 && (
        <Output>
          {results.map((r) => (
            <Item text={renderFunction(r)} key={getUUID()} clicked={clicked(r)} />
          ))}
        </Output>
      )}
      {results && results.length === 0 && (
        <Output>
          <Text
            text={translation.UI.autocomplete.searchEmpty}
            size={theme.size.sm}
            align="center"
          />
        </Output>
      )}
    </StyledAutocomplete>
  )
}

export default Autocomplete
