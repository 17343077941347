import { Route, Routes, Navigate } from "react-router-dom";
import Estates from "./Estates/Estates";
import Login from "./Login/Login";
import Managers from "./Managers/Managers";
import CaretakerCompany from "./CaretakerCompany/CaretakerCompany";
import Positions from "./Positions/Positions";

function Admin() {
  return (
    <Routes>
      <Route path={"estates"} element={<Estates />} />
      <Route path={"managers"} element={<Managers />} />
      <Route path={"caretaker-company"} element={<CaretakerCompany />} />
      <Route path={"positions"} element={<Positions />} />
      <Route index element={<Login />} />
      <Route path={"*"} element={<Navigate replace to="/" />} />
    </Routes>
  );
}

export default Admin;
