import { StyledFlex } from "./Flex.Styled";

const Flex = ({
  children,
  className,
  gap,
  margin,
  wrap,
  justify,
  align,
  flex,
}) => {
  return (
    <StyledFlex
      gap={gap}
      margin={margin}
      className={className}
      wrap={wrap}
      justify={justify}
      align={align}
      flex={flex}
    >
      {children}
    </StyledFlex>
  );
};

export default Flex;
