import { getPlainStreetName } from "./summarize";

const getFullStreetNumber = (streetName) => {
  const streetSplit = streetName.split(" ");
  const streetNumberIndex = streetSplit.findIndex((item) =>
    Number.isInteger(parseInt(item))
  );
  return streetSplit.slice(streetNumberIndex).join(" ");
};

const labelGenerator = (summarizedEstateArray) => {
  const firstEstate = summarizedEstateArray[0];
  const suffix = `, ${firstEstate.postcode} ${firstEstate.city}`;
  if (summarizedEstateArray.length === 1) {
    return `${firstEstate.street}${suffix}`;
  } else {
    const glue = summarizedEstateArray.length === 2 ? " + " : " - ";
    return `${getPlainStreetName(
      summarizedEstateArray[0].street
    )} ${getFullStreetNumber(firstEstate.street)}${glue}${getFullStreetNumber(
      summarizedEstateArray[summarizedEstateArray.length - 1].street
    )}${suffix}`;
  }
};

export default labelGenerator;
