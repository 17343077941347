import styled from "styled-components";
import Flex from "../UI/Flex/Flex";
import Text from "../UI/Text/Text";
import Hero from "../UI/Hero/Hero";
import Image from "../UI/Image/Image";

export const StyledTeam = styled.div`
  padding: 100px 0px;
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    padding: 50px 0px;
  }
`;

export const CustomSection = styled.div``;

export const Person = styled(Flex)`
  flex-wrap: nowrap;
  justify-content: space-between;
  padding: 15px 0px;
  border-top: thin solid ${({ theme }) => theme.color.gray};
  &:first-child {
    border-top: none;
  }
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    display: block;
  }
`;

export const SplitSection = styled.div`
  width: 50%;
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    width: 100%;
  }
`;
export const Name = styled(Text)`
  font-family: "bold";
  font-size: ${({ theme }) => theme.size.sm};
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    margin-bottom: 10px;
  }
`;
export const Tel = styled.a`
  display: block;
  color: ${({ theme }) => theme.color.black};
  text-decoration: none;
  font-size: ${({ theme }) => theme.size.sm};
  &:hover {
    text-decoration: underline;
  }
`;
export const Mail = styled.a`
  display: block;
  color: ${({ theme }) => theme.color.black};
  text-decoration: none;
  font-size: ${({ theme }) => theme.size.sm};
  &:hover {
    text-decoration: underline;
  }
`;
export const Description = styled(Text)`
  font-size: ${({ theme }) => theme.size.sm};
`;

export const CustomHero = styled(Hero)`
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
`;

export const CustomImage = styled(Image)`
  height: 250px;
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    height: 150px;
  }
`;
