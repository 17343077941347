import { StyledLink } from "./Link.Styled";
import { useNavigate } from "react-router-dom";

const Link = ({
  href,
  text,
  size,
  color,
  margin,
  className,
  newTab = false,
}) => {
  const navigate = useNavigate();

  const trigger = (e) => {
    e.preventDefault();
    if (newTab) {
      window.open(href, "_blank").focus();
    } else {
      navigate(href);
    }
  };

  return (
    <StyledLink
      href={href}
      onClick={trigger}
      size={size}
      color={color}
      margin={margin}
      className={className}
    >
      {text}
    </StyledLink>
  );
};

export default Link;
