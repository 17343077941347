import { useSelector } from "react-redux";
import Headline from "../../UI/Headline/Headline";
import {
  StyledOfficeHours,
  Section,
  Title,
  Content,
} from "./OfficeHours.Styled";
import Container from "../../UI/Container/Container";
import { getUUID } from "../../../utility";

const OfficeHours = () => {
  const translation = useSelector((s) => s.translation);
  const theme = useSelector((s) => s.theme);

  return (
    <StyledOfficeHours>
      <Headline
        text={translation.home.officeHours.headline}
        color={theme.color.white}
        withSeparator={true}
      />
      <Container>
        {translation.home.officeHours.sections.map(({ title, content }) => (
          <Section key={getUUID()}>
            <Title>{title}</Title>
            <Content>{content}</Content>
          </Section>
        ))}
      </Container>
    </StyledOfficeHours>
  );
};

export default OfficeHours;
