import { StyledForm } from "./Form.Styled";

const Form = ({ onSubmit, children, margin, className }) => {
  const submit = (e) => {
    e.preventDefault();
    onSubmit();
  };
  return (
    <StyledForm onSubmit={submit} margin={margin} className={className}>
      {children}
    </StyledForm>
  );
};

export default Form;
